export default function ListaAsociados({ lang, asociados, ruta }) {

    return (
        <>
            <div className="apartment-inner2-section-area sp7 bg2">
                <div className="container">
                    <div className="row">
                        {
                            asociados.map((unasociado, index) => (
                                <div className="col-lg-4 col-md-6" data-aos="zoom-in-up" data-aos-duration={800 + (index * 100)} key={index}>
                                    <div className="apartment-boxarea">
                                        <div className="img1">
                                            {unasociado.logo ?
                                                <img src={ruta + "/propietarios/" + unasociado.id + "/logo/" + unasociado.logo} alt="" />
                                                :
                                                ""
                                            }
                                        </div>
                                        <div className="content-area">
                                            <a href="#">{unasociado.nombre}</a>
                                            <div className="space16"></div>
                                            <i className="fa-light fa-location-dot"></i> {unasociado.direccion_fiscal}<br />
                                            {unasociado.ciudad_fiscal}, {unasociado.codigo_postal_fiscal}<br />
                                            <i className="fa-light fa-browser"></i> {unasociado.web2}<br />
                                            <i className="fa-light fa-phone"></i> {unasociado.telefono_fiscal}<br />
                                            <i className="fa-light fa-envelope"></i> {unasociado.email_contacto}
                                            <div className="space20"></div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
