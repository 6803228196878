import { useTranslation } from "react-i18next";

export default function Servicios() {

    const { t } = useTranslation();

    return (
        <>
            {/*--===== SERVICE AREA STARTS =======--*/}
            <div className="service2-section-area sp1">
                <div className="container">
                    <div className="row">
                        <div className="m-auto col-lg-12">
                            <div className="text-center heading2 space-margin60">
                                <h5 data-aos="fade-left" data-aos-duration="800" style={{ lineHeight: "30px" }}>{t("Asociación de apartamentos turísticos Costa Daurada y Terres de l’Ebre")}</h5>
                                <div className="space20"></div>
                                <h2 className="text-anime-style-3">{t("Empresas y Profesionales")}</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-4" data-aos="zoom-in-up" data-aos-duration="800">
                            <div className="amenities-boxarea">
                                <div className="img1">
                                    <i className="fa-solid fa-user-tie" style={{ fontSize: "80px" }}></i>
                                </div>
                                <div className="space32"></div>
                                <div className="content-area">
                                    <a href="#">{t("Asesoramiento a nuestros asociados")}</a>
                                    <div className="space18"></div>
                                    <p>{t("Ayudamos a nuestros asociados a tratar con las administraciones")}</p>
                                    <h3>01</h3>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-4" data-aos="zoom-in-up" data-aos-duration="1000">
                            <div className="amenities-boxarea">
                                <div className="img1">
                                    <i className="fa-solid fa-chalkboard-user" style={{ fontSize: "80px" }}></i>
                                </div>
                                <div className="space32"></div>
                                <div className="content-area">
                                    <a href="apartment-single.html">{t("Formación a los asociados")}</a>
                                    <div className="space18"></div>
                                    <p>{t("Realizamos cursos de gestión de apartamentos turísticos")}</p>
                                    <h3>02</h3>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-4" data-aos="zoom-in-up" data-aos-duration="1100">
                            <div className="amenities-boxarea">
                                <div className="img1">
                                    <i className="fa-solid fa-circle-info" style={{ fontSize: "80px" }}></i>
                                </div>
                                <div className="space32"></div>
                                <div className="content-area">
                                    <a href="apartment-single.html">{t("Tramitación de demandas")}</a>
                                    <div className="space18"></div>
                                    <p>{t("Ayudamos a tramitar los permisos necesarios")}</p>
                                    <h3>03</h3>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-4" data-aos="zoom-in-up" data-aos-duration="1200">
                            <div className="amenities-boxarea">
                                <div className="img1">
                                    <i className="fa-solid fa-handshake" style={{ fontSize: "80px" }}></i>
                                </div>
                                <div className="space32"></div>
                                <div className="content-area">
                                    <a href="apartment-single.html">{t("Convenios con empresas externas")}</a>
                                    <div className="space18"></div>
                                    <p>{t("Tenemos acuerdos con otros sectores ligados al turismo")}</p>
                                    <h3>04</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*--===== SERVICE AREA ENDS =======--*/}
        </>
    )
}
