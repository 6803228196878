import ReactHtmlParser from 'react-html-parser';

export default function TextoConFoto({ ruta, texto, titulo, tamanyo = 3 }) {
    return (
        <>
            {/*--===== BOLSA TRABAJO TEXT AREA STARTS =======--*/}
            <div className="mt-2 blog-details-leftarea sp4">
                <div className="container">
                    <div className="row" style={{ textAlign: "center" }}>
                        <div className={"m-auto col-lg-" + tamanyo}>
                            <h5 data-aos="fade-left" data-aos-duration="800" className="aos-init aos-animate tituloredondeado">{titulo}</h5>
                        </div>
                    </div>
                    <div className="space20"></div>
                    <div className="row">
                        <div className="m-auto col-lg-7">
                            <div className="blog-author-area">
                                {
                                    texto.imagen_cabecera ?
                                        <>
                                            <div className="img1">
                                                <img src={ruta + "/" + texto.imagen_cabecera} alt="" />
                                            </div>
                                            <div className="space40"></div>
                                        </>
                                        :
                                        null
                                }
                                {ReactHtmlParser(texto.ladesc)}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/*--===== BOLSA TRABAJO TEXT AREA ENDS =======--*/}
        </>
    )
}
