import { useTranslation } from "react-i18next";

export default function Servicios() {

    const { t } = useTranslation();

    return (
        <>
            {/*--===== SERVICE AREA STARTS =======--*/}
            <div className="service3-section-area sp1">
                <div className="container">
                    <div className="row">
                        <div className="m-auto col-lg-6">
                            <div className="text-center heading3 space-margin60">
                                <h5 data-aos="fade-left" data-aos-duration="800">{t("Asociación de apartamentos turísticos")}</h5>
                                <div className="space20"></div>
                                <h2 className="text-anime-style-3">{t("Costa Daurada y Terres de l'Ebre")}</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-4" data-aos="zoom-in-up" data-aos-duration="800">
                            <div className="amenities-boxarea">
                                <div className="img1">
                                    <img src="/images/web/costadaurada/servicios/HOME_ASOCIACION.jpg" alt="" />
                                </div>
                                <div className="space32"></div>
                                <div className="content-area">
                                    <a href="#">{t("La asociación")}</a>
                                    <div className="space18"></div>
                                    <p>Nuestra Asociación está presidida por Joan Calvet, y representa el sector de apartamentos en los municipios de Costa Daurada y Terres de l'Ebre. Fue fundada el 19 de julio de 1977 y es una Asociación totalmente privada y sin ánimo de lucro.</p>
                                    <h3>01</h3>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4" data-aos="zoom-in-up" data-aos-duration="1000">
                            <div className="space40 d-lg-block d-none"></div>
                            <div className="amenities-boxarea">
                                <div className="img1">
                                    <img src="/images/web/costadaurada/servicios/HOME_ALQUILER.jpg" alt="" />
                                </div>
                                <div className="space32"></div>
                                <div className="content-area">
                                    <a href="apartment-single.html">{t("Alquiler")}</a>
                                    <div className="space18"></div>
                                    <p>Disfruta de unas vacaciones hechas a tu medida y vive cómodamente en cualquiera de nuestros magníficos alojamientos. Sin prisas y a tu gusto. En família, en pareja o como prefieras. Tú solo tienes que escoger dónde quieres pasar tus vacaciones.</p>
                                    <h3>02</h3>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4" data-aos="zoom-in-up" data-aos-duration="1100">
                            <div className="amenities-boxarea">
                                <div className="img1">
                                    <img src="/images/web/costadaurada/servicios/PORTADA_AT_2.jpg" alt="" />
                                </div>
                                <div className="space32"></div>
                                <div className="content-area">
                                    <a href="apartment-single.html">{t("Su entorno")}</a>
                                    <div className="space18"></div>
                                    <p>Es uno de los principales destinos turísticos del Mediterráneo. Múltiples son las opciones que ofrecen sus 202 kilómetros de litoral y los contrastes de las comarcas más interiores. La Costa Daurada y Terres de l'Ebre pone a disposición de sus visitantes una oferta turística de calidad.</p>
                                    <h3>03</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*--===== SERVICE AREA ENDS =======--*/}
        </>
    )
}
