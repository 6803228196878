import { useTranslation } from "react-i18next";

export default function Galeria() {

    const { t } = useTranslation();

    return (
        <>
            {/*--===== GALLERY AREA STARTS =======--*/}
            <div className="gallery6-section-area sp6">
                <div className="container">
                    <div className="row">
                        <div className="m-auto col-lg-5">
                            <div className="text-center gallery-header heading6 space-margin60">
                                <h5 data-aos="fade-left" data-aos-duration="800">{t("Nuestros colaboradores")}</h5>
                                <div className="space20"></div>
                                <h2 className="text-anime-style-3">{t("Convenios de colaboración con empresas externas")}</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="space40"></div>
                <div className="wrapper">
                    <div className="center-slider">
                        <div>
                            <div className="img1">
                                <img src="/images/web/costadaurada/colaboradores/connectivity_logo_vert_color-(2)weblgo.png" alt="" />
                            </div>
                        </div>
                        <div>
                            <div className="img1">
                                <img src="/images/web/costadaurada/colaboradores/iman-temporing-weblgo.png" alt="" />
                            </div>
                        </div>
                        <div>
                            <div className="img1">
                                <img src="/images/web/costadaurada/colaboradores/logo_black_nobg.png" alt="" />
                            </div>
                        </div>
                        <div>
                            <div className="img1">
                                <img src="/images/web/costadaurada/colaboradores/logo_garamond.jpg" alt="" />
                            </div>
                        </div>
                        <div>
                            <div className="img1">
                                <img src="/images/web/costadaurada/colaboradores/logo_goontraining.jpg" alt="" />
                            </div>
                        </div>
                        <div>
                            <div className="img1">
                                <img src="/images/web/costadaurada/colaboradores/logo_tourist_check.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*--===== GALLERY AREA ENDS =======--*/}
        </>
    )
}
