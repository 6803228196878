import { React } from "react";
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';

export default function Content({ lang, estudios }) {

    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    return (
        <div className="mt-2 apartment-inner2-section-area sp4 bg2">
            {/* **** ESTUDIOS ***** */}
            <div className="container">
                <div className="section ">
                    <div className="clearfix section_wrapper">
                        <div className="clearfix mt-20 column_attr align_center">
                            <h6 className="landing4-heading grey" style={{ paddingBottom: "3px", borderBottom: "1px solid  #00b7d9", borderColor: "#00b7d9" }}>{t("ESTUDIOS")}</h6>
                            <hr className="no_line" style={{ margin: "0 auto 15px auto" }} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    {
                        estudios.map((unestudio, index) => (
                            <div className="mt-4 col-lg-4 minh-200" style={{ borderBottom: "1px solid  #00b7d9" }} key={index}>
                                <h3 className="fs-18 lh-25">
                                    <img src="/images/web/costadaurada/corporation2-relations-pic2.png" width="30" height="36" className="imgestudios" style={{ marginRight: "10px" }} />
                                    {unestudio.nombre}
                                </h3>
                                <div className="space8"></div>
                                <h6 style={{ fontWeight: "400" }}> {new Date(unestudio.created_at).toLocaleDateString()}</h6>
                                <div className="space8"></div>
                                <div style={{ width: "100%", paddingBottom: "3px", marginBottom: "20px", lineHeight: "21px" }} className="fs-13">
                                    {ReactHtmlParser(unestudio.respuesta)}
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            {/* ****FIN ESTUDIOS ***** */}
        </div>
    )
}
