import { useTranslation } from "react-i18next";

export default function ListaNoticias({ noticias, ruta, lang, config }) {
    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    return (
        <>
            {/*--===== BLOG AREA STARTS =======--*/}
            <div className="blog-inner-section-area sp4">
                <div className="container">
                    <div className="row">
                        <div className="m-auto col-lg-2">
                            <h5 data-aos="fade-left" data-aos-duration="800" className="aos-init aos-animate tituloredondeado">{t("Noticias")}</h5>
                        </div>
                    </div>
                    <div className="space40"></div>
                    <div className="row">
                        {
                            noticias.map((unanoticia, index) => (
                                <div className="col-lg-4 col-md-6" key={index}>
                                    <div className="blog-boxarea">
                                        <div className="img1 image-anime">
                                            {
                                                unanoticia.imagen ?
                                                    <img src={ruta + "/blog/" + unanoticia.id + "/" + unanoticia.imagen} alt={"Blog - " + index} />
                                                    :
                                                    <img src="/images/web/bedloop-nofoto.png" alt={"Blog - " + index} />
                                            }
                                        </div>
                                        <div className="content-area">
                                            <ul>
                                                <li><a href="#"><i className="fa-regular fa-calendar texto-azul"></i> {new Date(unanoticia.fecha_publica).toLocaleDateString()} </a></li>
                                            </ul>
                                            <div className="space20"></div>
                                            <a href={"/" + preidioma + t("blog") + "/" + unanoticia.laurl}>{unanoticia.eltitulo}</a>
                                            <div className="space24"></div>
                                            <a href={"/" + preidioma + t("blog") + "/" + unanoticia.laurl} className="readmore">{t("Leer más")}<i className="fa-solid fa-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            {/*--===== BLOG AREA ENDS =======--*/}
        </>
    )
}
