import { useTranslation } from "react-i18next";

export default function TextoSobreNosotros({ sobrenosotros, ruta }) {

    const { t } = useTranslation();

    return (
        <>
            {/*--===== ABOUT AREA STARTS =======--*/}
            <div className="mt-2 about1-section-area sp1">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5">
                            <div className="about-header heading3">
                                <h5 data-aos="fade-left" data-aos-duration="800">{t("Sobre nosotros")}</h5>
                                <div className="space20"></div>
                                <p data-aos="fade-left" data-aos-duration="900">Nuestra Asociación fue fundada el 19 de julio de 1977. En la actualidad representamos a más de 80 empresas y profesionales del sector de apartamentos, que ofrecen su oferta legalizada de apartamentos turísticos, en todo el territorio, un global de más de 5.000 alojamientos y 24.734 plazas, distribuidos a lo largo de toda la Costa Daurada y las Terres de l’Ebre, donde recibimos y acomodamos a millones de visitantes.</p>
                                <div className="space16"></div>
                            </div>
                        </div>
                        <div className="col-lg-1"></div>
                        <div className="col-lg-6">
                            <div className="space60 d-lg-none d-block"></div>
                            <div className="about-images">
                                <div className="img1 reveal image-anime">
                                    <img src="/images/web/costadaurada/quienessomos/imagen1.jpg" />
                                </div>
                                <div className="img2 reveal image-anime">
                                    <img src="/images/web/costadaurada/quienessomos/imagen2.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*--===== ABOUT AREA ENDS =======-*/}
        </>
    );
}
