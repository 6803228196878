import { useTranslation } from "react-i18next";

export default function Objetivos() {
    const { t } = useTranslation();

    return (
        <>
            {/*--===== OTHERS FACT AREA STARTS =======--*/}
            <div className="others1-section-area sp4">
                <div className="container">
                    <div className="row">
                        <div className="m-auto col-lg-12">
                            <div className="text-center heading2 space-margin60">
                                <h5 data-aos="fade-left" data-aos-duration="800" style={{ lineHeight: "30px" }}>{t("Asociación de apartamentos turísticos Costa Daurada y Terres de l’Ebre")}</h5>
                                <div className="space20"></div>
                                <h2 className="text-anime-style-3">{t("Objetivos")}</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-6" data-aos="fade-up" data-aos-duration="800">
                            <div className="others-box">
                                <div className="icons">
                                    <i className="fa-solid fa-scale-balanced"></i>
                                </div>
                                <div className="space16"></div>
                                <p>{t("Defender los intereses de nuestros asociados.")}</p>
                            </div>
                            <div className="space30 d-lg-none d-block"></div>
                        </div>
                        <div className="col-lg-3 col-md-6" data-aos="fade-down" data-aos-duration="1000">
                            <div className="others-box">
                                <div className="icons">
                                    <i className="fa-solid fa-umbrella-beach"></i>
                                </div>
                                <div className="space16"></div>
                                <p>{t("Promover el turismo en la Costa Daurada y Terres de l'Ebre")}</p>
                            </div>
                            <div className="space30 d-lg-none d-block"></div>
                        </div>
                        <div className="col-lg-3 col-md-6" data-aos="fade-up" data-aos-duration="1100">
                            <div className="others-box">
                                <div className="icons">
                                    <i className="fa-solid fa-gavel"></i>
                                </div>
                                <div className="space16"></div>
                                <p>{t("Legalizar el sector frente a la competencia desleal.")}</p>
                            </div>
                            <div className="space30 d-md-none d-block"></div>
                        </div>
                        <div className="col-lg-3 col-md-6" data-aos="fade-down" data-aos-duration="1200">
                            <div className="others-box">
                                <div className="icons">
                                    <i className="fa-solid fa-volume"></i>
                                </div>
                                <div className="space16"></div>
                                <p>{t("Ser el portavoz de las necesidades del sector.")}</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6" data-aos="fade-down" data-aos-duration="1300">
                            <div className="others-box">
                                <div className="icons">
                                    <i className="fa-solid fa-comment-dots"></i>
                                </div>
                                <div className="space16"></div>
                                <p>{t("Fomentar el diálogo con la administración.")}</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6" data-aos="fade-down" data-aos-duration="1400">
                            <div className="others-box">
                                <div className="icons">
                                    <i className="fa-solid fa-building"></i>
                                </div>
                                <div className="space16"></div>
                                <p>{t("Estar presentes en las instituciones turísticas.")}</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6" data-aos="fade-down" data-aos-duration="1500">
                            <div className="others-box">
                                <div className="icons">
                                    <i className="fa-regular fa-message-exclamation"></i>
                                </div>
                                <div className="space16"></div>
                                <p>{t("Concienciar a la administración sobre la importancia del turismo.")}</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6" data-aos="fade-down" data-aos-duration="1500">
                            <div className="others-box">
                                <div className="icons">
                                    <i className="fa-solid fa-circle-exclamation"></i>
                                </div>
                                <div className="space16"></div>
                                <p>{t("Estar atentos a cualquier situación que nos afecte.")}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* --===== OTHERS FACT AREA ENDS =======--*/}
        </>
    )
}
