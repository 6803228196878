import { React, useState } from "react";
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import ReactHtmlParser from 'react-html-parser';

export default function Content({ lang, normativas, notas }) {

    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    const [showtitulo, setShowTitulo] = useState('block');

    const [shownormas, setShowNormas] = useState('none');
    const handleShowNormas = () => {
        if (shownormas == "none") {
            setShowNormas("block");
            setShowTitulo("none");
            setShowNotas("none");
        }
        else setShowNormas("none");
    }


    const [shownotas, setShowNotas] = useState('none');
    const handleShowNotas = () => {
        if (shownotas == "none") {
            setShowNotas("block");
            setShowTitulo("none");
            setShowNormas("none")
        }
        else setShowNotas("none");
    }



    const handleSearch = () => {
        let palabra = document.getElementById("paraula").value;
        let token = document.getElementById('meta_token').getAttribute('content');

        const result = axios.post('/busca-notas', {
            palabra: palabra,
        }, {
            headers: {
                'content-type': 'text/json',
                'X-CSRF-TOKEN': token
            }
        })
            .then((response) => {
                let respuesta = "";
                let notas = response.data.notas;

                if (notas.length > 0) {
                    respuesta += "<div class='row' style='width:100%;padding-bottom: 3px;border-bottom: 1px solid  #00b7d9; border-color: #00b7d9;margin-bottom:20px;'>";
                    respuesta += "   <div class='col-lg-4' style='margin-bottom:10px'>";
                    respuesta += "       <b>" + t("Título") + "</b>";
                    respuesta += "   </div>";
                    respuesta += "   <div class='col-lg-4' style='margin-bottom:10px;'>";
                    respuesta += "       <b>" + t("Fecha") + "</b>";
                    respuesta += "   </div>";
                    respuesta += "   <div class='col-lg-4' style='margin-bottom:10px; text-align:center;'>";
                    respuesta += "       <b>" + t("Enlace") + "</b>";
                    respuesta += "   </div>";
                    respuesta += "</div>";

                    for (var i = 0; i < notas.length; i++) {
                        var unanota = notas[i];

                        respuesta += "<div class='row' style='width:100%;padding-bottom: 3px;border-bottom: 1px solid  #00b7d9;border-color: #00b7d9;margin-bottom:20px;'>";
                        respuesta += "  <div class='col-lg-4' style='margin-bottom:10px'>";
                        respuesta += "      <h5>";
                        respuesta += "          <img src='/images/web/costadaurada/corporation2-relations-pic2.png' width='15' height='15' style='margin-right: 10px;'> " + unanota.nombre;
                        respuesta += "      </h5>";
                        respuesta += "  </div>";
                        respuesta += "  <div class='col-lg-4' style='margin-bottom:10px'>";
                        respuesta += "      <h6 style='font-weight:400;'> " + new Date(unanota.created_at).toLocaleDateString() + "</h6>";
                        respuesta += "  </div>";
                        respuesta += "  <div class='col-lg-4' style='margin-bottom:10px; text-align:end;'>";
                        respuesta += "      <span style='font-size:12px'>" + unanota.respuesta + "</span>";
                        respuesta += "  </div>";
                        respuesta += "</div>";
                    }
                }
                document.querySelectorAll(".notasres")[0].innerHTML = respuesta;
            })
    }

    const pressEnter = (e) => {
        if (e.code === "Enter") {
            e.preventDefault();
            handleSearch();
        }
    }
    return (
        <>
            {/*--===== OTHERS AREA STARTS =======--*/}
            <div className="others-author-area">
                <div className="container">
                    <div className="row">
                        <div className="m-auto col-lg-10">
                            <div className="auhtor-tabs-area">
                                <div className="boxes">
                                    <div className="icons" style={{ background: "#00b7d9" }}>
                                        <i className="fa-solid fa-newspaper"></i>
                                    </div>
                                    <div className="content">
                                        <a onClick={handleShowNormas} className="curpointer">{t("NORMATIVA")}</a>
                                    </div>
                                </div>

                                <div className="boxes">
                                    <div className="icons" style={{ background: "#adcd04" }}>
                                        <i className="fa-solid fa-map"></i>
                                    </div>
                                    <div className="content">
                                        <a onClick={handleShowNotas} className="curpointer">{t("NOTAS")}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*--===== OTHERS AREA ENDS =======--*/}

            <div className="mt-2 apartment-inner2-section-area sp5 bg2">
                <div className="container">
                    <div className="row" style={{ display: showtitulo }} >
                        <div className="m-auto col-lg-7" style={{ textAlign: "center" }}>
                            <h5 data-aos="fade-left" data-aos-duration="800" className="aos-init aos-animate tituloredondeado">{t("Seleccione la sección a visualizar")}</h5>
                        </div>
                    </div>
                    <div className="space40"></div>

                    {/* **** NORMATIVA ***** */}
                    <div className="row normas" style={{ display: shownormas }}>
                        <div className="section col-lg-12">
                            <div className="clearfix section_wrapper">
                                <div className="clearfix mt-20 column_attr align_center">
                                    <h6 className="landing4-heading grey" style={{ paddingBottom: "3px", borderBottom: "1px solid  #00b7d9", borderColor: "#00b7d9" }}>{t("NORMATIVA")}</h6>
                                    <hr className="no_line" style={{ margin: "0 auto 15px auto" }} />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 row">
                            {
                                normativas.map((unanorma, index) => (
                                    <div className="mt-4 col-lg-4" style={{ borderBottom: "1px solid  #00b7d9" }} key={index}>
                                        <h3 className="fs-18 lh-25">
                                            <img src="/images/web/costadaurada/corporation2-relations-pic2.png" width="30" height="36" className="imgnorma" style={{ marginRight: "10px" }} />
                                            {unanorma.nombre}
                                        </h3>
                                        <div className="space8"></div>
                                        <h6 style={{ fontWeight: "400" }}> {new Date(unanorma.created_at).toLocaleDateString()}</h6>
                                        <div className="space8"></div>
                                        <div style={{ width: "100%", paddingBottom: "3px", display: "inline-block", marginBottom: "20px", lineHeight: "21px" }} className="fs-13">
                                            {ReactHtmlParser(unanorma.respuesta)}
                                        </div>
                                    </div>
                                ))
                            }
                        </div>

                    </div>

                    {/* **** NOTAS ***** */}
                    <div className="row notas" style={{ display: shownotas }}>
                        <div className="section col-lg-12">
                            <div className="clearfix section_wrapper">
                                <div className="clearfix mt-20 column_attr align_center">
                                    <h6 className="landing4-heading grey" style={{ paddingBottom: "3px", borderBottom: "1px solid  #00b7d9", display: "inline-block", borderColor: "#00b7d9" }}>{t("NOTAS")}</h6>
                                    <hr className="no_line" style={{ margin: "0 auto 15px auto" }} />
                                </div>
                            </div>
                        </div>
                        <div className="entry_content col-lg-12">
                            <div className="clearfix section_wrapper">
                                <form id="notasform" className="row">
                                    <div className="col-lg-9 col-12">
                                        <div className="apartment-list-area space-margin60" style={{ justifyContent: "left" }}>
                                            <div className="input-area" style={{ marginTop: "0" }}>
                                                <input type="text" name="paraula" id="paraula" aria-required="true" aria-invalid="false" placeholder={t(" Buscar")} onKeyDown={(e) => pressEnter(e)} />
                                            </div>
                                            <div className="btn-area1" style={{ marginTop: "0", marginLeft: "20px" }}>
                                                <button type="button" className="header-btn4" id="submit_popup" onClick={handleSearch} >{t("Buscar")}</button>

                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div className="space40"></div>
                                <div className="notasres">
                                    <div className="row" style={{ width: "100%", paddingBottom: "3px", borderBottom: "1px solid #00b7d9", borderColor: "#00b7d9", marginBottom: "20px" }}>
                                        <div className="col-lg-4 col-4" style={{ marginBottom: "10px" }}>
                                            <b>{t("Título")}</b>
                                        </div>
                                        <div className="col-lg-4 col-4" style={{ marginBottom: "10px" }}>
                                            <b>{t("Fecha")}</b>
                                        </div>
                                        <div className="col-lg-4 col-4" style={{ marginBottom: "10px", textAlign: "center" }}>
                                            <b>{("Enlace")}</b>
                                        </div>
                                    </div>
                                    {
                                        notas.map((unanota, index) => (
                                            <div className="row" style={{ width: "100%", paddingbBottom: "3px", borderBottom: "1px solid  #00b7d9", borderColor: "#00b7d9", marginBottom: "20px" }} key={index}>
                                                <div className="col-lg-4 col-4" style={{ marginBottom: "10px" }}>
                                                    <h5>
                                                        <img src="/images/web/costadaurada/corporation2-relations-pic2.png" width="15" height="15" style={{ marginRight: "10px" }} />
                                                        {unanota.nombre}
                                                    </h5>
                                                </div>
                                                <div className="col-lg-4 col-4" style={{ marginBottom: "10px" }}>
                                                    <h6 style={{ fontWeight: "400" }}> {new Date(unanota.created_at).toLocaleDateString()}</h6>
                                                </div>
                                                <div className="col-lg-4 col-4" style={{ marginBottom: "10px", textAlign: "end" }}>
                                                    <span style={{ fontSize: "12px" }} dangerouslySetInnerHTML={{ __html: unanota.respuesta }}>
                                                    </span>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
