import React from 'react';

import "./../../../../css/web/costadaurada/bootstrap.min.css";
import "./../../../../css/web/costadaurada/aos.css";
import "./../../../../css/web/costadaurada/magnific-popup.css";
import "./../../../../css/web/costadaurada/mobile.css";
import "./../../../../css/web/costadaurada/owlcarousel.min.css";
import "./../../../../css/web/costadaurada/sidebar.css";
import "./../../../../css/web/costadaurada/slick-slider.css";
import "./../../../../css/web/costadaurada/nice-select.css";
import "./../../../../css/web/costadaurada//main.css";

import Scripts from './Compartido/Scripts.jsx';
import Preloader from './Compartido/Preloader.jsx';
import Header from './Compartido/Header.jsx';
import Footer from './Compartido/Footer.jsx';
import CabeceraInterior from './Compartido/CabeceraInterior.jsx';
import TextoNoticia from './FichaNoticia/TextoNoticia.jsx';

import { useTranslation } from 'react-i18next';

import Noticias from './Inicio/Noticias.jsx';

const FichaNoticia = (props) => {

    const { t } = useTranslation();

    return (
        <body className="homepage3-body">
            <Scripts />
            <Preloader />
            <Header
                config={props.websgrupo}
                meta_titulo={props.meta_titulo}
                meta_descripcion={props.meta_descripcion}
                meta_keywords={props.meta_keywords}
                ruta={props.ruta}
                lang={props.lang}
                destinos={props.destinos}
            />
            <CabeceraInterior
                titulo={t("Noticia")}
                titulo2={props.lanoticia.eltitulo}
                ruta={props.ruta}
                lang={props.lang}
            />
            <TextoNoticia
                noticia={props.lanoticia}
                ruta={props.ruta}
            />
            <Noticias
                noticias={props.populares}
                ruta={props.ruta}
                lang={props.lang}
            />
            <Footer
                config={props.websgrupo}
                ruta={props.ruta}
                lang={props.lang}
            />
        </body>
    );
}

export default FichaNoticia;
