import axios from "axios";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function LangChanger({ idiomas, lang }) {

    const { i18n } = useTranslation();

    useEffect(() => {
        i18n.changeLanguage(lang);
    }, []);

    const handleLangSelect = (countryCode) => {

        console.log(countryCode);

        i18n.changeLanguage(countryCode);

        let token = document.getElementById('meta_token').getAttribute('content');

        const result = axios.post('/cambiaidioma', {
            lang: countryCode
        }, {
            headers: {
                'content-type': 'text/json',
                'X-CSRF-TOKEN': token
            }
        })
            .then((response) => {
                console.log(response.data);
                console.log("cambiado voy a recargar");
                window.location.reload();
            })
            .catch((error) => {
                console.log("Error");
                console.log(error);
            });
    }

    return (
        <>
            <li>
                <a href="#"><img src={"/images/web/costadaurada/idioma/" + (lang ? lang : "es") + "Flag.png"} /> <i className="fa-solid fa-angle-down"></i></a>
                <ul className="dropdown-padding">
                    {
                        idiomas.map((idioma, index) => (
                            <li key={index}>
                                <a onClick={() => handleLangSelect(idioma.codigo)} href="#">
                                    {idioma.nombre}
                                </a>
                            </li>
                        ))
                    }
                </ul>
            </li>
        </>
    )
}
