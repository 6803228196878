import ReactHtmlParser from 'react-html-parser';

export default function OtroTextoConFotosDerecha({ ruta, texto, titulo }) {
    return (
        <>
            {/*--===== TEXT AREA STARTS =======--*/}
            <div className="mt-2 about1-section-area sp1">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5">
                            <div className="about-header heading3">
                                <h5 data-aos="fade-left" data-aos-duration="800">{titulo}</h5>
                                <div className="space20"></div>
                                <div data-aos="fade-left" data-aos-duration="900">
                                    {ReactHtmlParser(texto.ladesc)}
                                </div>
                                <div className="space32"></div>
                            </div>
                        </div>
                        <div className="col-lg-1"></div>
                        <div className="col-lg-6">
                            <div className="space60 d-lg-none d-block"></div>
                            <div className="about-images">
                                <div className="img1 reveal image-anime">
                                    {
                                        texto.imagen_cabecera ?
                                            <img src={ruta + "/" + texto.imagen_cabecera} alt="" />
                                            :
                                            <img src="https://images.pexels.com/photos/29006461/pexels-photo-29006461/free-photo-of-vista-aerea-del-complejo-turistico-catalonia-beachfront.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />

                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*--===== TEXT AREA ENDS =======--*/}
        </>
    )
}
