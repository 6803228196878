import { useTranslation } from "react-i18next";

export default function Menmbresia() {
    const { t } = useTranslation();

    return (
        <>
            {/*--===== GALLERY AREA STARTS =======--*/}
            <div className="gallery5-section-area sp6">
                <div className="container">
                    <div className="row">
                        <div className="m-auto col-lg-6">
                            <div className="text-center galler-header heading5 space-margin60">
                                <h5 data-aos="fade-left" data-aos-duration="800">our gallery</h5>
                                <div className="space20"></div>
                                <h2 className="text-anime-style-3">{t("Somos miembros de")}</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="gallery-slider-area owl-carousel">
                                <div className="content-area">
                                    <div className="img1">
                                        <img src="/images/web/costadaurada/miembro/logo_feht.png" alt="" />
                                    </div>
                                    <div className="icons">
                                        <a href="gallery-v1.html"><i className="fa-solid fa-plus"></i></a>
                                    </div>
                                </div>

                                <div className="content-area">
                                    <div className="img1">
                                        <img src="/images/web/costadaurada/miembro/logo-catalunya.jpg" alt="" />
                                    </div>
                                    <div className="icons">
                                        <a href="gallery-v1.html"><i className="fa-solid fa-plus"></i></a>
                                    </div>
                                </div>

                                <div className="content-area">
                                    <div className="img1">
                                        <img src="/images/web/costadaurada/miembro/logo-costa-daurada.png" alt="" />
                                    </div>
                                    <div className="icons">
                                        <a href="gallery-v1.html"><i className="fa-solid fa-plus"></i></a>
                                    </div>
                                </div>

                                <div className="content-area">
                                    <div className="img1">
                                        <img src="/images/web/costadaurada/miembro/logo-federatur.png" alt="" />
                                    </div>
                                    <div className="icons">
                                        <a href="gallery-v1.html"><i className="fa-solid fa-plus"></i></a>
                                    </div>
                                </div>

                                <div className="content-area">
                                    <div className="img1">
                                        <img src="/images/web/costadaurada/miembro/logo-mossos-desquadra.png" alt="" />
                                    </div>
                                    <div className="icons">
                                        <a href="gallery-v1.html"><i className="fa-solid fa-plus"></i></a>
                                    </div>
                                </div>

                                <div className="content-area">
                                    <div className="img1">
                                        <img src="/images/web/costadaurada/miembro/logo-pct-transparent.png" alt="" />
                                    </div>
                                    <div className="icons">
                                        <a href="gallery-v1.html"><i className="fa-solid fa-plus"></i></a>
                                    </div>
                                </div>

                                <div className="content-area">
                                    <div className="img1">
                                        <img src="/images/web/costadaurada/miembro/observatori-logo.jpg" alt="" />
                                    </div>
                                    <div className="icons">
                                        <a href="gallery-v1.html"><i className="fa-solid fa-plus"></i></a>
                                    </div>
                                </div>



                            </div>
                            <div className="space30"></div>
                        </div>
                    </div>
                </div>
            </div>
            {/*--===== GALLERY AREA ENDS =======--*/}
        </>
    )
}
