import { useTranslation } from "react-i18next"

export default function Carousel({ destinos, d_ale, ruta }) {

    const { t } = useTranslation();

    const imagenes = destinos[d_ale].imagenes;

    return (
        <>
            {/*--===== HERO AREA STARTS =======--*/}
            <div className="header-carousel-area2 owl-carousel">
                {
                    imagenes.map((unaimagen, index) => (
                        <div className="main-hero-area" key={index}>
                            <div className="img1">
                                <img src={ruta + '/destinos/' + destinos[d_ale].id + '/' + unaimagen.imagen} alt="" />
                            </div>
                            <div className="bg-elements">
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <div className="header-boxarea">
                                            <h5>6000{index} Just Meadow East Alfonso</h5>
                                            <div className="space20"></div>
                                            <h2>Moon Light Villa {index}</h2>
                                            <div className="space28"></div>
                                            <p><s>${index}.900,000</s> ${index}.800,000</p>
                                            <div className="space32"></div>
                                            <div className="btn-area1">
                                                <a href="contact.html" className="header-btn2">Schedule A Visit</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
            {/*--===== HERO AREA ENDS =======--*/}
        </>
    )
}
