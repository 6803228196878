import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LangChanger from './LangChanger';
import { Modal } from 'react-bootstrap';
import axios from 'axios';

export default function Header({ config, ruta, lang, destinos, experiencias, idiomas }) {

    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    const cookieValue = document.cookie.split('; ').filter(row => row.startsWith('nombre=')).map(c => c.split('=')[1])[0];


    useEffect(() => {
        if (config.id === 1) {
            let favicon = ruta + "/web/favicon/favicon.ico"
            let fav = document.querySelector("link[rel='icon']")
            fav.setAttribute("href", favicon)
        }
        else {
            let favicon = ruta + "/web/favicon/" + config.id + "/favicon.ico"
            let fav = document.querySelector("link[rel='icon']")
            fav.setAttribute("href", favicon)
        }

        if (config.css_personalizado) {
            const linkElement = document.createElement('link');
            linkElement.rel = 'stylesheet';
            linkElement.type = 'text/css';
            linkElement.href = config.css_personalizado;
            const headElement = document.head;
            headElement.appendChild(linkElement);
        }

    }, []);

    const [showLogin, setShowLogin] = useState(false);

    const handleShowLogin = () => {
        setShowLogin(!showLogin)
    }

    const handleSubmit = (e) => {
        console.log("Hago login");
        e.preventDefault()
        let token = document.getElementById('meta_token').getAttribute('content');

        const result = axios.post('/dologinApartur', {
            user: e.target.usuario.value,
            password: e.target.password.value,
        }, {
            headers: {
                'content-type': 'text/json',
                'X-CSRF-TOKEN': token
            }
        })
            .then((response) => {
                if (response.data.type == 'success') {
                    let date = new Date();
                    date.setTime(date.getTime() + (60 * 60 * 1000));
                    document.cookie = "nombre = " + response.data.nombre.substring(0, 8) + "; expires = " + date.toGMTString();
                    location.replace("/" + preidioma + t("mis-datos"));
                }
                else {
                    document.getElementsByClassName("solucion")[0].innerHTML = t(response.data.message);
                }
            });
    }

    const fesLogout = () => {
        document.cookie = 'nombre=; Max-Age=-99999999;';
        location.replace("/");
    }


    return (
        <>
            {/*--=====HEADER START=======--*/}
            <header>
                <div className="header-area homepage3 header header-sticky d-none d-lg-block " id="header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="header-elements">
                                    <div className="site-logo">
                                        <a href={"/" + preidioma}>
                                            {
                                                config.logo_fiscal != "" ?
                                                    <img src={ruta + "/web/logo/" + config.logo_fiscal} alt="" />
                                                    : <img src="https://www.bedloop.com/imatges/logo.webp" alt="" />
                                            }
                                        </a>
                                    </div>
                                    <div className="main-menu">
                                        <ul>
                                            <li>
                                                <a href="#">{t("La Asociación")} <i className="fa-solid fa-angle-down"></i></a>
                                                <ul className="dropdown-padding">
                                                    <li><a href={"/" + preidioma + t("organos-de-gobierno")}>{t("Órganos de gobierno")}</a></li>
                                                    <li><a href={"/" + preidioma + t("presencia-institutional")}>{t("Presencia institucional")}</a></li>
                                                    <li><a href={"/" + preidioma + t("que-hacemos")}>{t("Servicios")}</a></li>
                                                    <li><a href={"/" + preidioma + t("sobre-nosotros")}>{t("Campañas")}</a></li>
                                                    <li><a href={"/" + preidioma + t("iniciativas")}>{t("Iniciativas sociales")}</a></li>
                                                    <li><a href={"/" + preidioma + t("bolsa-trabajo")}>{t("Bolsa de Trabajo")}</a></li>
                                                </ul>
                                            </li>
                                            <li><a href={"/" + preidioma + t("los-asociados")}>{t("Asociados")}</a></li>
                                            <li><a href={"/" + preidioma + t("club-colaborador")}>{t("Club colaborador")}</a></li>
                                            <li><a href={"/" + preidioma + t("alquiler")}>{t("Alquiler")}</a></li>
                                            <li><a href={"/" + preidioma + t("blog")}>{t("Noticias")}</a></li>
                                            <li><a href={"/" + preidioma + t("contacto")}>{t("Contacto")}</a></li>

                                            <LangChanger
                                                idiomas={idiomas}
                                                lang={lang}
                                            />
                                        </ul>
                                    </div>
                                    <div className="btn-area">
                                        <a href={"/" + preidioma + t("asociate")} className="header-btn2">{t("Asóciate")}</a>
                                    </div>
                                    {
                                        cookieValue ?
                                            <div className="main-menu">
                                                <ul>
                                                    <li>
                                                        <a href="#">{t("HOLA") + " " + cookieValue} <i className="fa-solid fa-angle-down"></i></a>
                                                        <ul className="dropdown-padding">
                                                            <li><a href={"/" + preidioma + t("area-juridica")}><span>{t("AREA JURÍDICA")}</span></a></li>
                                                            <li><a href={"/" + preidioma + t("estudios")}><span>{t("ESTUDIOS")}</span></a></li>
                                                            <li><a href={"/" + preidioma + t("estadisticas")}><span>{t("ESTADISTÍCAS")}</span></a></li>
                                                            <li><a href={"/" + preidioma + t("newsletters")}><span>{t("NEWSLETTTERS")}</span></a></li>
                                                            <li><a href={"/" + preidioma + t("mis-datos")}><span>{t("MIS DATOS")}</span></a></li>
                                                            <li><a onClick={() => fesLogout()} className="curpointer"><span>{t("SALIR")}</span></a></li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </div>
                                            :
                                            <div className="btn-area">
                                                <a data-toggle="modal" id="loginpc" className="btn login-sup-inici header-btn6" onClick={() => handleShowLogin()} style={{ backgroundColor: "rgb(173, 205, 4)" }}>{t("LOG IN")}</a>
                                            </div>
                                    }
                                    <div className="body-overlay"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </header >
            {/*--=====HEADER END =======--*/}

            {/*--==== MODAL LOGIN ====== --*/}
            <Modal
                show={showLogin}
                onHide={() => setShowLogin(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    {t("Entrar")}
                </Modal.Header>
                <Modal.Body>
                    <div className="contact-inner-area">
                        <div className="contact-inner-boxarea" style={{ padding: "0 0 16px 56px" }}>
                            <div className="row align-items-center">
                                <div className="col-lg-12 col-10">
                                    <div className="contact-input-section">
                                        <form onSubmit={handleSubmit} >
                                            <div className="input-area">
                                                <div className="space20"></div>
                                                <input type="text" placeholder={t("Usuario")} name="usuario" />
                                            </div>
                                            <div className="input-area">
                                                <div className="space20"></div>
                                                <input type="password" placeholder={t("Contraseña")} name="password" />
                                            </div>
                                            <div className="input-area text-end">
                                                <div className="space32"></div>
                                                <button type="submit" className="header-btn4">{t("Entrar")}</button>
                                            </div>
                                        </form>
                                        <div className="solucion"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal >
            {/*--==== END MODAL LOGIN ====== --*/}

            {/*--===== MOBILE HEADER STARTS =======--*/}
            <div className="mobile-header mobile-haeder3 d-block d-lg-none">
                <div className="container-fluid">
                    <div className="col-12">
                        <div className="mobile-header-elements">
                            <div className="mobile-logo">
                                <a href={"/" + preidioma}>
                                    {
                                        config.logo_fiscal != "" ?
                                            <img src={ruta + "/web/logo/" + config.logo_fiscal} alt="" />
                                            : <img src="https://www.bedloop.com/imatges/logo.webp" alt="" />
                                    }
                                </a>
                            </div>
                            <div className="mobile-nav-icon dots-menu">
                                <i className="fa-solid fa-bars"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mobile-sidebar mobile-sidebar3">
                <div className="logosicon-area">
                    <div className="logos">
                        {
                            config.logo_fiscal != "" ?
                                <img src={ruta + "/web/logo/" + config.logo_fiscal} alt="" />
                                : <img src="https://www.bedloop.com/imatges/logo.webp" alt="" />
                        }
                    </div>
                    <div className="menu-close">
                        <i className="fa-solid fa-xmark"></i>
                    </div>
                </div>
                <div className="mobile-nav mobile-nav1">
                    <ul className="mobile-nav-list nav-list1">
                        <li>
                            <a href="#" >{t("La asociacion")} </a>
                            <ul className="sub-menu">
                                <li><a href={"/" + preidioma + t("organos-de-gobierno")}>{t("Órganos de gobierno")}</a></li>
                                <li><a href={"/" + preidioma + t("presencia-institutional")}>{t("Presencia institucional")}</a></li>
                                <li><a href={"/" + preidioma + t("que-hacemos")}>{t("Servicios")}</a></li>
                                <li><a href={"/" + preidioma + t("sobre-nosotros")}>{t("Campañas")}</a></li>
                                <li><a href={"/" + preidioma + t("iniciativas")}>{t("Iniciativas sociales")}</a></li>
                                <li><a href={"/" + preidioma + t("bolsa-trabajo")}>{t("Bolsa de Trabajo")}</a></li>

                            </ul>
                        </li>
                        <li><a href={"/" + preidioma + t("los-asociados")}>{t("Asociados")}</a></li>
                        <li><a href={"/" + preidioma + t("club-colaborador")}>{t("Club colaborador")}</a></li>
                        <li><a href={"/" + preidioma + t("alquiler")}>{t("Alquiler")}</a></li>
                        <li><a href={"/" + preidioma + t("blog")}>{t("Noticias")}</a></li>
                        <li><a href={"/" + preidioma + t("contacto")}>{t("Contacto")}</a></li>

                        {
                            cookieValue ?
                                <li>
                                    <a href="#">{t("HOLA") + " " + cookieValue} </a>
                                    <ul className="sub-menu">
                                        <li><a href={"/" + preidioma + t("area-juridica")}><span>{t("AREA JURÍDICA")}</span></a></li>
                                        <li><a href={"/" + preidioma + t("estudios")}><span>{t("ESTUDIOS")}</span></a></li>
                                        <li><a href={"/" + preidioma + t("estadisticas")}><span>{t("ESTADISTÍCAS")}</span></a></li>
                                        <li><a href={"/" + preidioma + t("newsletters")}><span>{t("NEWSLETTTERS")}</span></a></li>
                                        <li><a href={"/" + preidioma + t("mis-datos")}><span>{t("MIS DATOS")}</span></a></li>
                                        <li><a onClick={() => fesLogout()} className="curpointer"><span>{t("SALIR")}</span></a></li>
                                    </ul>
                                </li>
                                :
                                <div className="btn-area">
                                    <a data-toggle="modal" id="loginmobile" className="btn login-sup-inici header-btn6" onClick={() => handleShowLogin()} style={{ backgroundColor: "rgb(173, 205, 4)" }}>{t("LOG IN")}</a>
                                </div>
                        }
                    </ul>

                    <div className="allmobilesection">
                        <a href={"/" + preidioma + t("asociate")} className="header-btn3">{t("Asóciate")} <span><i className="fa-solid fa-arrow-right"></i></span></a>
                        <div className="single-footer">
                            <h3>{t("Datos de contacto")}</h3>
                            <div className="footer1-contact-info">
                                <div className="contact-info-single">
                                    <div className="contact-info-icon">
                                        <i className="fa-solid fa-phone-volume"></i>
                                    </div>
                                    <div className="contact-info-text">
                                        <a href={"tel:" + config.telefono_fiscal}>{config.telefono_fiscal}</a>
                                    </div>
                                </div>

                                <div className="contact-info-single">
                                    <div className="contact-info-icon">
                                        <i className="fa-solid fa-envelope"></i>
                                    </div>
                                    <div className="contact-info-text">
                                        <a href={"mailto:" + config.email_fiscal}>{config.email_fiscal}</a>
                                    </div>
                                </div>

                                <div className="contact-info-single">
                                    <div className="contact-info-icon">
                                        <i className="fa-brands fa-whatsapp"></i>
                                    </div>

                                    <div className="contact-info-text">
                                        <a href="https://wa.me/+34 687 336 787">+34 687 336 787</a>
                                    </div>
                                </div>

                                <div className="single-footer">
                                    <h3>{t("Direccion")}</h3>
                                    <div className="contact-info-single">
                                        <div className="contact-info-icon">
                                            <i className="fa-solid fa-location-dot"></i>
                                        </div>
                                        <div className="contact-info-text">
                                            <a href="mailto:at@tinet.cat" >{config.direccion_fiscal}<br /> {config.codigo_postal_fiscal + " " + config.poblacion_fiscal} Tarragona</a>
                                        </div>
                                    </div>
                                </div>

                                <div className="single-footer">
                                    <h3>{t("Enlaces sociales")}</h3>
                                    <div className="social-links-mobile-menu">
                                        <ul>
                                            <li><a href={config.url_facebook} target="_blank"><i className="fa-brands fa-facebook-f"></i></a></li>
                                            <li>
                                                <a href={config.url_twitter} target="_blank">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="twitterlogohead">{/*--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--*/}<path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" /></svg>
                                                </a>
                                            </li>
                                            <li><a href={config.url_linkedin} target="_blank"><i className="fa-brands fa-linkedin-in"></i></a></li>
                                            <li><a href={config.url_pinterest} target="_blank"><i className="fa-brands fa-youtube"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*--===== MOBILE HEADER STARTS =======--*/}
        </>
    )
}
