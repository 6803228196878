import { useTranslation } from "react-i18next";
import { CookieBanner } from '@keepist/react-gdpr-cookie-banner';
import Swal from "sweetalert2";
import axios from "axios";
import { useState } from "react";
import context from "react-bootstrap/esm/AccordionContext";


export default function Footer({ config, ruta, lang }) {

    const { t } = useTranslation();

    const [nombre, setNombre] = useState('');
    const [telefono, setTelefono] = useState('');
    const [mensaje, setMensaje] = useState('');

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    const handleSubmit = (e) => {
        e.preventDefault();
        const url = e.target.action;

        var mensajeerror = "";

        if (nombre === "" || telefono === "" || mensaje === "") {

            if (nombre === "") mensajeerror += `${t("Nombre")}<br/>`;
            if (telefono === "") mensajeerror += `${t('Teléfono')}<br/>`;
            if (mensaje === "") mensajeerror += `${t('Mensaje')}<br/>`;

            Swal.fire({
                title: `${t('Error')}`,
                html: `${t('Todos los campos obligatorios')}<br/>` + mensajeerror,
                timer: 2500,
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeClick: false
            });
        } else {
            let elnombre = nombre;
            let eltelefono = telefono;
            let elmensaje = mensaje;
            let elidioma = document.getElementById("idioma").value;

            let token = document.getElementById('meta_token').getAttribute('content');
            const result = axios.post(url, {
                yourname: elnombre,
                Phone: eltelefono,
                contactMessage: eltema + "<br/>" + elmensaje,
                lang: elidioma
            }, {
                headers: {
                    'content-type': 'text/json',
                    'X-CSRF-TOKEN': token
                }
            })
                .then((response) => {
                    console.log(response);
                    if (response.data.type === "error") {
                        Swal.fire({
                            title: "KO!",
                            html: `${t('Contacto no enviado')}`,
                            timer: 1500,
                            showConfirmButton: false,
                            allowOutsideClick: false,
                        });
                    } else {
                        Swal.fire({
                            title: "OK!",
                            html: `${t('Contacto enviado')}`,
                            timer: 1500,
                            showConfirmButton: false,
                            allowOutsideClick: false,
                        }).then((result) => {
                            window.location.reload();
                        });
                    }
                });
        }
    };

    return (
        <>
            {/*--===== FOOTER AREA STARTS =======--*/}
            <div className="footer3-section-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="footer-instagram-area">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="footer-contact-box" data-aos="zoom-in-up" data-aos-duration="1000">
                                            <h3>{t("AAT es miembro y colaborador de:")}</h3>
                                            <div className="space16"></div>
                                            <div className="row">
                                                <div className="col-lg-3" style={{ alignContent: "center" }}>
                                                    <img className="maxw150" src="/images/web/costadaurada/miembro/logo_feht.png" alt="" />
                                                </div>
                                                <div className="col-lg-3" style={{ alignContent: "center" }}>
                                                    <img className="maxw150" src="/images/web/costadaurada/miembro/logo-catalunya.jpg" alt="" />
                                                </div>
                                                <div className="col-lg-3" style={{ alignContent: "center" }}>
                                                    <img className="maxw150" src="/images/web/costadaurada/miembro/logo-costa-daurada.png" alt="" />
                                                </div>
                                                <div className="col-lg-3" style={{ alignContent: "center" }}>
                                                    <img className="maxw150" src="/images/web/costadaurada/miembro/logo-federatur.png" alt="" />
                                                </div>
                                                <div className="col-lg-3" style={{ alignContent: "center" }}>
                                                    <img className="maxw150" src="/images/web/costadaurada/miembro/logo-mossos-desquadra.png" alt="" />
                                                </div>
                                                <div className="col-lg-3" style={{ alignContent: "center" }}>
                                                    <img className="maxw150" src="/images/web/costadaurada/miembro/logo-pct-transparent.png" alt="" />
                                                </div>
                                                <div className="col-lg-3" style={{ alignContent: "center" }}>
                                                    <img className="maxw150" src="/images/web/costadaurada/miembro/observatori-logo.jpg" alt="" />
                                                </div>
                                                <div className="col-lg-3" style={{ alignContent: "center" }}>
                                                    <img className="maxw150" src="/images/web/costadaurada/logos_footer.png" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="space20"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="footer-instagram-area">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="footer-contact-box" data-aos="zoom-in-up" data-aos-duration="1000">
                                            <h3>{t("Contacte con nosotros")}</h3>
                                            <div className="space16"></div>
                                            <form id="contactForm" action="/enviacontacto" onSubmit={handleSubmit}>
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="space16"></div>
                                                        <div className="input-area">
                                                            <input type="text" placeholder={t("Su nombre") + " *"} name="yourname" value={nombre} onChange={(e) => setNombre(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="space16"></div>
                                                        <div className="input-area">
                                                            <input type="number" placeholder={t("Su teléfono") + " *"} name="Phone" required="" value={telefono} onChange={(e) => setTelefono(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="space16"></div>
                                                        <div className="input-area">
                                                            <textarea placeholder={t("Mensaje") + " *"} name="contactMessage" required="" value={mensaje} onChange={(e) => setMensaje(e.target.value)}></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="space32"></div>
                                                        <div className="input-area text-end">
                                                            <button type="submit" className="header-btn4">{t("Enviar")}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="space40"></div>
                <div className="footer3-bottom-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="footer-bottom-area">
                                    <div className="footer-menu-area">
                                        <div className="footer-logo">
                                            {
                                                config.logo_fiscal != "" ?
                                                    <img src={ruta + "/web/logo/" + config.logo_fiscal} alt="" />
                                                    : <img src="https://www.bedloop.com/imatges/logo.webp" alt="" />
                                            }
                                        </div>
                                        <div className="footer-menu">
                                            <ul>
                                                <li><a href={"/" + preidioma + t("legal")}>{t("Aviso Legal")}</a></li>
                                                <li><a href={"/" + preidioma + t("cookies")}>{t("Política de cookies")}</a></li>
                                                <li><a href={"/" + preidioma + t("contacto")}>{t("Contacto")}</a></li>
                                            </ul>
                                        </div>
                                        <div className="footer-menu">
                                            <ul>
                                                <li><a href={"/" + preidioma + t("politica-de-privacidad")}>{t("Política de privacidad")}</a></li>
                                                <li><a href={"/" + preidioma + t("terminos")}>{t("Términos y condiciones")}</a></li>
                                            </ul>
                                        </div>
                                        <div className="footer-menu2">
                                            <ul>
                                                <li><a href="#"> <span><i className="fa-solid fa-location-dot"></i></span> <span>{config.direccion_fiscal} <br /> {config.codigo_postal_fiscal} {config.poblacion_fiscal}<br />{config.nif_fiscal} </span></a></li>
                                                <li className="space24"></li>
                                                <li><a href={"tel:" + config.telefono_fiscal}><span><i className="fa-solid fa-phone"></i></span> <span>{config.telefono_fiscal}</span></a></li>
                                                <li className="space24"></li>
                                                <li><a href="https://wa.me/+34 687 336 787"><span><i className="fa-brands fa-whatsapp"></i></span><span>+34 687 336 787</span></a></li>
                                            </ul>
                                        </div>
                                        <div className="footer-social">
                                            <ul>
                                                <li><a href={config.url_facebook} target="_blank"><i className="fa-brands fa-facebook-f"></i></a></li>
                                                <li>
                                                    <a href={config.url_twitter} target="_blank">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="twitterlogofooter">{/*--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--*/}<path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" /></svg>
                                                    </a>
                                                </li>
                                                <li><a href={config.url_linkedin} target="_blank"><i className="fa-brands fa-linkedin-in"></i></a></li>
                                                <li><a href={config.url_pinterest} target="_blank"><i className="fa-brands fa-youtube"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="space48"></div>
                                            <div className="copyright-area">
                                                <p>© {new Date().getFullYear()} {config.nombre_fiscal} </p>
                                                <br />
                                                <p>{t("Hecho con")}  <i className="fa-solid fa-heart" style={{ color: "red" }}></i> {t("en")} <a target="_blank" rel="nofollow" href="https://www.bedloop.com?utm_source=sponsor&utm_medium=web&utm_campaign=atcostadaurada&utm_id=apartur" style={{ textDecoration: "none" }}>BedLoop</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            {/*--===== FOOTER AREA ENDS =======--*/}

            < CookieBanner
                message={t("Utilizamos cookies para ofrecerte una mejor experiencia de navegación, personalizar el contenido y los anuncios, proporcionar funciones de medios sociales y analizar nuestro tráfico.Aceptas nuestras cookies si continúas utilizando este sitio web. Lee sobre cómo utilizamos las cookies y cómo puedes controlarlas haciendo clic en el enlace siguiente:")}
                wholeDomain={true}
                privacyPolicyLinkText={t("Lee nuestra Política de cookies")}
                policyLink={"/" + preidioma + t("cookies")}
                necessaryOptionText={t("Necesarias")}
                preferencesOptionText={t("Rendimiento")}
                statisticsOptionText={t("Análisis")}
                marketingOptionText={t("Márqueting")}
                acceptAllButtonText={t("Aceptar todas")}
                acceptSelectionButtonText={t("Aceptar selección")}
                showAcceptSelectionButton={true}
                styles={{
                    dialog: { position: "fixed", top: "auto", left: "0px", right: "0px", zIndex: "100000", backgroundColor: "#FFF", padding: "10px", bottom: "0px", color: "#333" },
                    container: { width: "100%" },
                    message: { color: "#333", minHeight: "32px", fontSize: "10pt", fontWeight: "400", lineHeight: "130%", padding: "10px 20px" },
                    optionLabel: { color: "#333" },
                    selectPane: { padding: "3px 0px 10px 20px" },
                    checkbox: { left: "-5px", marginLeft: "10px", position: "relative" },
                    button: { color: "#fff", backgroundColor: config.color_corporativo_web, marginRight: "10px", padding: "3px 10px 3px 10px", borderRadius: "5px", border: "1px solid " + config.color_corporativo_web },
                    policy: { fontSize: "10pt", marginTop: "-9px", marginLeft: "10px", color: "#333", position: "absolute", textDecoration: "underline" }
                }
                }
            />
        </>
    )
}
