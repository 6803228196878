import { useTranslation } from "react-i18next";
import ReactHtmlParser from 'react-html-parser';

export default function TextoNoticia({ noticia, ruta }) {
    const { t } = useTranslation();

    return (
        <>
            {/*--===== BLOG AREA STARTS =======--*/}
            <div className="blog-details-leftarea sp6">
                <div className="container">
                    <div className="row">
                        <div className="m-auto col-lg-7">
                            <div className="blog-author-area">
                                <h2>{noticia.eltitulo}</h2>
                                <div className="space20"></div>
                                <div className="img1">
                                    {noticia.imagen ?
                                        <img src={ruta + "/blog/" + noticia.id + "/" + noticia.imagen} alt="" />
                                        :
                                        <img src="/images/web/bedloop-nofoto.png" alt="" />
                                    }
                                </div>
                                <div className="space40"></div>
                                <ul>
                                    <li><a href="#"><i className="fa-regular fa-calendar texto-azul"></i> {new Date(noticia.fecha_publica).toLocaleDateString()}</a></li>
                                </ul>
                                <div className="space32"></div>
                                {ReactHtmlParser(noticia.ladesccorta)}
                                <div className="space16"></div>
                                {ReactHtmlParser(noticia.ladesclarga)}
                                <div className="space40"></div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/*--===== BLOG AREA ENDS =======--*/}
        </>
    )
}
