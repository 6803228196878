import { useTranslation } from "react-i18next";

export default function Footer({ config, ruta, lang }) {

    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    return (
        <>
            {/*--===== FOOTER AREA STARTS =======--*/}
            <div className="footer4-section-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="footer-menu-area">
                                <div className="footer-elements">
                                    <div className="footer-logo">
                                        {
                                            config.logo_fiscal != "" ?
                                                <img src={ruta + "/web/logo/" + config.logo_fiscal} alt="" />
                                                : <img src="https://www.bedloop.com/imatges/logo.webp" alt="" />
                                        }
                                    </div>
                                    <div className="footer-menu">
                                        <ul>
                                            <li><a href={"/" + preidioma + t("legal")}>{t("Aviso Legal")}</a></li>
                                            <li><a href={"/" + preidioma + t("cookies")}>{t("Política de cookies")}</a></li>
                                            <li><a href={"/" + preidioma + t("contacto")}>{t("Contacto")}</a></li>
                                        </ul>
                                    </div>
                                    <div className="footer-social">
                                        <ul>
                                            <li><a href={config.url_facebook}><i className="fa-brands fa-facebook-f"></i></a></li>
                                            <li>
                                                <a href={config.url_twitter}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="twitterlogofooter">{/*--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--*/}<path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" /></svg>
                                                </a>
                                            </li>
                                            <li><a href={config.url_linkedin}><i className="fa-brands fa-linkedin-in"></i></a></li>
                                            <li><a href={config.url_pinterest}><i className="fa-brands fa-youtube"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="m-auto col-lg-5">
                                        <div className="text-center footer-call-area">
                                            <a href={"tel:" + config.telefono_fiscal}>{config.telefono_fiscal}</a>
                                            <div className="space36"></div>
                                            <ul>
                                                <li><a href="#"> <span><i className="fa-solid fa-location-crosshairs"></i></span> <span>{config.direccion_fiscal}<br className="d-lg-block d-none" /> {config.codigo_postal_fiscal + " " + config.poblacion_fiscal}</span></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="copyright-area">
                                    <p>© {new Date().getFullYear()} {config.nombre_fiscal}</p>
                                    <ul>
                                        <li><a href={"/" + preidioma}>{t("Política de privacidad")}</a></li>
                                        <li><span> | </span></li>
                                        <li><a href={"/" + preidioma}>{t("Términos y condiciones")}</a></li>
                                        <li><span> | </span></li>
                                        <li>{t("Hecho con")}  <i className="fa-solid fa-heart" style={{ color: "red" }}></i> {t("en")} <a target="_blank" rel="nofollow" href="https://www.bedloop.com?utm_source=sponsor&utm_medium=web&utm_campaign=apartur&utm_id=apartur" style={{ textDecoration: "none" }}>BedLoop</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*--===== FOOTER AREA ENDS =======--*/}
        </>
    )
}
