import { React } from "react";
import { Container } from 'react-bootstrap';

const DatosExperiencia = ({ experiencia }) => {
    return (
        <div className="pb-3" style={{ paddingTop: '4rem' }}>
            <h1 className="mb-5 text-center">{experiencia.elnombre}</h1>
            <Container>
                <div className="noticia-individual-descripcio-llarga">
                    <div dangerouslySetInnerHTML={{ __html: experiencia.eltexto }}></div>
                </div>
            </Container>
        </div>
    );
}

export default DatosExperiencia;
