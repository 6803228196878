import { useTranslation } from "react-i18next";
import axios from 'axios';

export default function ListaAsociados({ lang, asociados, ruta }) {

    const { t } = useTranslation();

    const handleSearch = () => {

        let palabra = document.getElementById("paraula").value;
        let token = document.getElementById('meta_token').getAttribute('content');

        const result = axios.post('/busca-asociado-cd', {
            palabra: palabra,
        }, {
            headers: {
                'content-type': 'text/json',
                'X-CSRF-TOKEN': token
            }
        })
            .then((response) => {
                let respuesta = "";
                let socis = response.data.socis;
                if (socis.length > 0) {
                    for (var i = 0; i < socis.length; i++) {
                        var unsoci = socis[i];
                        if (unsoci['web'] != "") var laweb = unsoci['web'];
                        else var laweb = "#"

                        var duracion = 800 + (i * 100);

                        respuesta += "<div class='col-lg-4 col-md-6' data-aos='zoom-in-up' data-aos-duration='" + duracion + "'>";
                        respuesta += "  <div class='apartment-boxarea'>";
                        respuesta += "      <div class='img1'>";
                        respuesta += "          <img src='" + ruta + "/propietarios/" + unsoci['id'] + "/logo/" + unsoci['logo'] + "' alt='' >";
                        respuesta += "      </div>";
                        respuesta += "      <div class='content-area'>";
                        respuesta += "          <a href='#'>" + unsoci['nombre'] + "</a>";
                        respuesta += "          <div class='space16'></div>";
                        respuesta += "          <i class='fa-light fa-location-dot texto-azul'></i> " + unsoci['direccion_fiscal'] + "<br>" + unsoci['ciudad_fiscal'] + ", " + unsoci['codigo_postal_fiscal'] + "<br>";
                        if (unsoci['web2']) {
                            respuesta += "      <div class='space8'></div>";
                            respuesta += "      <i class='fa-light fa-browser texto-azul'></i> " + unsoci['web2'] + "<br>";
                        }
                        if (unsoci['telefono_fiscal']) {
                            respuesta += "      <div class='space8'></div>";
                            respuesta += "      <i class='fa-light fa-phone texto-azul'></i> " + unsoci['telefono_fiscal'] + "<br>";
                        }
                        if (unsoci['email_contacto']) {
                            respuesta += "      <div class='space8'></div>";
                            respuesta += "      <i class='fa-light fa-envelope texto-azul'></i> " + unsoci['email_contacto'] + "<br>";
                        }
                        respuesta += "          <div class='space20'></div>";
                        respuesta += "      </div>";
                        respuesta += "  </div>";
                        respuesta += "</div>";
                    }
                }
                document.querySelectorAll(".listaasociados")[0].innerHTML = respuesta;

            });


    }

    const pressEnter = (e) => {
        if (e.code === "Enter") {
            e.preventDefault();
            handleSearch();
        }
    }

    return (
        <>
            <div className="mt-2 apartment-inner2-section-area sp4 bg2">
                <div className="container">
                    <div className="mb-3 row">
                        <div className="col-lg-12">
                            <form id="notasform" className="row">
                                <div className="col-lg-12 col-12">
                                    <div className="apartment-list-area space-margin20" style={{ justifyContent: "center" }}>
                                        <div className="input-area" style={{ marginTop: "0" }}>
                                            <input type="text" name="paraula" id="paraula" aria-required="true" aria-invalid="false" placeholder={t("Poblacion")} onKeyDown={(e) => pressEnter(e)} />
                                        </div>
                                        <div className="btn-area1" style={{ marginTop: "0", marginLeft: "20px" }}>
                                            <button type="button" className="header-btn4" id="submit_popup" onClick={handleSearch} >{t("Buscar")}</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="space20"></div>
                    <div className="row" style={{ textAlign: "center" }}>
                        <div className="m-auto col-lg-2">
                            <h5 data-aos="fade-left" data-aos-duration="800" className="aos-init aos-animate tituloredondeado">{t("Asociados")}</h5>
                        </div>
                    </div>
                    <div className="space40"></div>
                    <div className="row listaasociados">
                        {
                            asociados.map((unasociado, index) => (
                                <div className="col-lg-4 col-md-6" data-aos="zoom-in-up" data-aos-duration={800 + (index * 100)} key={index}>
                                    <div className="apartment-boxarea">
                                        <div className="img1">
                                            {unasociado.logo ?
                                                <img src={ruta + "/propietarios/" + unasociado.id + "/logo/" + unasociado.logo} alt="" />
                                                :
                                                ""
                                            }
                                        </div>
                                        <div className="content-area">
                                            <a href="#">{unasociado.nombre}</a>
                                            <div className="space16"></div>
                                            <i className="fa-light fa-location-dot texto-azul"></i> {unasociado.direccion_fiscal}<br />
                                            {unasociado.ciudad_fiscal}, {unasociado.codigo_postal_fiscal}<br />
                                            {
                                                unasociado.web2 ?
                                                    <>
                                                        <div className="space8"></div>
                                                        <i className="fa-light fa-browser texto-azul"></i> {unasociado.web2}<br />
                                                    </>
                                                    :
                                                    ""
                                            }
                                            {
                                                unasociado.telefono_fiscal ?
                                                    <>
                                                        <div className="space8"></div>
                                                        <i className="fa-light fa-phone texto-azul"></i> {unasociado.telefono_fiscal}<br />
                                                    </>
                                                    :
                                                    ""
                                            }
                                            {
                                                unasociado.email_contacto ?
                                                    <>
                                                        <div className="space8"></div>
                                                        <i className="fa-light fa-envelope texto-azul"></i> {unasociado.email_contacto}</>
                                                    :
                                                    ""
                                            }
                                            <div className="space20"></div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
