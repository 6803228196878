import { React } from "react";
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'


const Content = ({ lang, datospropietario, elpropietario, ruta }) => {

    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";


    const mostracontrasenya1 = () => {
        if (document.getElementById("lapassword").getAttribute('type') == "password") {
            document.getElementById("lapassword").setAttribute('type', "text");
        }
        else {
            document.getElementById("lapassword").setAttribute('type', "password");
        }
    };

    const mostracontrasenya2 = () => {
        if (document.getElementById("lapasswordrepe").getAttribute('type') == "password") {
            document.getElementById("lapasswordrepe").setAttribute('type', "text");
        }
        else {
            document.getElementById("lapasswordrepe").setAttribute('type', "password");
        }
    };

    const handleSubmit = (e) => {
        console.log("click envio")
        let token = document.getElementById('meta_token').getAttribute('content');

        let laweb = document.getElementById("laweb").value;
        let lapassword = document.getElementById("lapassword").value;
        let username = document.getElementById("username").value;
        let company = document.getElementById("company").value;
        let idasociado = document.getElementById("idasociado").value;
        let idpropietario = document.getElementById("idpropietario").value;

        const formData = new FormData();
        formData.append("web", laweb);
        formData.append("lapassword", lapassword);
        formData.append("username", username);
        formData.append("company", company);
        formData.append("idasociado", idasociado);
        formData.append("idpropietario", idpropietario);

        const result = axios.post('/modificadatos', formData,
            {
                headers: {
                    'X-CSRF-TOKEN': token,
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((response) => {
                document.getElementsByClassName("resultado")[0].innerHTML = t("Datos actualizados");
                location.reload();
            });
    }

    // specify upload params and url for your files
    const getUploadParams = ({ file, meta }) => {
        const body = new FormData()
        body.append('fileField', file);
        return { url: 'https://httpbin.org/post', body }
    }

    // called every time a file's `status` changes
    const handleChangeStatus = ({ meta, file }, status) => { }

    // receives array of files that are done uploading when submit button is clicked
    const handleSubmitFoto = (files, allFiles) => {

        const formData = new FormData();
        formData.append('file', files[0]['file']);

        let token = document.getElementById('meta_token').getAttribute('content');
        const result = axios.post('/uploadLogo/' + elpropietario.id, formData,
            {
                headers: {
                    'X-CSRF-TOKEN': token,
                    'Content-Type': 'multipart/form-data'
                }
            })
        allFiles.forEach(f => f.remove())
        location.reload();
    }

    return (
        <>
            {/*--===== MIS DATOS AREA STARTS =======--*/}
            <div className="contact-inner-area sp6">
                <div className="container">
                    <div className="row">
                        <div className="m-auto col-lg-2">
                            <h5 data-aos="fade-left" data-aos-duration="800" className="aos-init aos-animate tituloredondeado">{t("Mis Datos")}</h5>
                        </div>
                    </div>
                    <div className="space40"></div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="contact-inner-boxarea">
                                <div className="row align-items-center">
                                    <div className="col-lg-12">
                                        <div className="contact-input-section">
                                            <form id="modificaForm">
                                                <input type="hidden" name="idasociado" id="idasociado" value={datospropietario.id} />
                                                <input type="hidden" name="idpropietario" id="idpropietario" value={elpropietario.id} />
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="space20"></div>
                                                        <div className="input-area">
                                                            <label to="company">{t("Empresa")}</label>
                                                            <input placeholder={t("Empresa")} type="text" name="company" id="company" size="100" aria-required="true" aria-invalid="false" defaultValue={elpropietario.nombre} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="space20"></div>
                                                        <div className="input-area">
                                                            <label to="username">{t("Usuario") + " *"}</label>
                                                            <input placeholder={t("Usuario") + "*"} type="text" name="username" id="username" size="100" aria-required="true" aria-invalid="false" defaultValue={datospropietario.username} />
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6">
                                                        <div className="space20"></div>
                                                        <div className="input-area">
                                                            <label to="username">{t("Contraseña") + " *"}</label>
                                                            <input placeholder={t("Contraseña") + "*"} type="password" name="lapassword" id="lapassword" size="40" aria-required="true" aria-invalid="false" defaultValue={datospropietario.password} />
                                                            <i className="fa-light fa-eye verContrasenya pointer" style={{ top: "-40px", cursor: "pointer", left: "auto", right: "8px", float: "right", position: "relative" }} onClick={() => mostracontrasenya1()}></i>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6">
                                                        <div className="space20"></div>
                                                        <div className="input-area">
                                                            <label to="username">{t("Confirmar contraseña") + " *"}</label>
                                                            <input placeholder={t("Confirmar contraseña") + " *"} type="password" name="lapasswordrepe" id="lapasswordrepe" size="40" aria-required="true" aria-invalid="false" defaultValue={datospropietario.password} />
                                                            <i className="fa-light fa-eye verContrasenya pointer" style={{ top: "-40px", cursor: "pointer", left: "auto", right: "8px", float: "right", position: "relative" }} onClick={() => mostracontrasenya2()}></i>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6">
                                                        <div className="space20"></div>
                                                        <div className="input-area">
                                                            <label to="username">{t("Web")}</label>
                                                            <input placeholder={t("Web")} type="text" name="laweb" id="laweb" size="200" aria-invalid="false" defaultValue={elpropietario.web} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="space20"></div>
                                                        {
                                                            (elpropietario.logo) ?
                                                                <div className="logofiscal">
                                                                    <img src={ruta + "/propietarios/" + elpropietario.id + "/logo/" + elpropietario.logo} style={{ width: "100px" }} />
                                                                    <input type="button" className='eliminarLogo' style={{ marginLeft: "20px" }} value={t("Eliminar logo")} />
                                                                </div>
                                                                :
                                                                <div className="logofiscal">
                                                                </div>
                                                        }
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="space20"></div>
                                                        <Dropzone
                                                            getUploadParams={getUploadParams}
                                                            onChangeStatus={handleChangeStatus}
                                                            onSubmit={handleSubmitFoto}
                                                            accept="image/*"
                                                            inputContent={t("Arrastre archivos o presione para subirlos")}
                                                            submitButtonContent={t("Subir")}
                                                            maxFiles="1"
                                                        />
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="space32"></div>
                                                        <div className="input-area text-end">
                                                            <button type="button" className="header-btn4" id="submit" onClick={handleSubmit}>{t("Enviar")}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                            <div className="resultado"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
            {/*--===== MIS DATOS AREA ENDS =======--*/}
        </>
    );
}

export default Content;
