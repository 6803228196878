import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import ReactHtmlParser from 'react-html-parser';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import { useState } from 'react';
import axios from 'axios';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const Link = ({ id, children, title }) => (
    <OverlayTrigger overlay={<Tooltip id={id}>{title}</Tooltip>}>
        <a href="#">{children}</a>
    </OverlayTrigger>
);

export default function DatosPropiedad({ propiedad, servicios, ruta, politicas, lang, config, extras, configweb, destino }) {

    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    const [nombre, setNombre] = useState('');
    const [telefono, setTelefono] = useState('');
    const [email, setEmail] = useState('');
    const [diaentrada, setDiaEntrada] = useState('');
    const [diasalida, setDiaSalida] = useState('');
    const [poblacion, setPoblacion] = useState('');
    const [idioma, setIdioma] = useState('');
    const [personas, setPersonas] = useState('');
    const [observaciones, setObservaciones] = useState('');
    const [consentimiento, setConsentimiento] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        const url = e.target.action;

        var mensajeerror = "";

        if (nombre === "" || email === "" || !consentimiento) {

            if (nombre === "") mensajeerror += `${t("Nombre")}<br/>`;
            if (email === "") mensajeerror += `${t('Email')}<br/>`;
            if (!consentimiento) mensajeerror += `${t("consentimiento")}<br/>`;

            Swal.fire({
                title: `${t('Error')}`,
                html: `${t('Todos los campos obligatorios')}<br/>` + mensajeerror,
                timer: 2500,
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeClick: false
            });
        } else {
            let elnombre = nombre;
            let eltelefono = telefono;
            let elmail = email;
            let dianetrada = dianetrada;
            let diasalida = diasalida;
            let lapoblacion = poblacion;
            let elidioma = idioma;
            let laspersonas = personas;
            let elmensaje = observaciones;

            let token = document.getElementById('meta_token').getAttribute('content');
            const result = axios.post(url, {
                yourname: elnombre,
                Phone: eltelefono,
                contactEmail: elmail,
                dianetrada: dianetrada,
                diasalida: diasalida,
                lapoblacion: lapoblacion,
                lang: elidioma,
                laspersonas: laspersonas,
                contactMessage: elmensaje
            }, {
                headers: {
                    'content-type': 'text/json',
                    'X-CSRF-TOKEN': token
                }
            })
                .then((response) => {
                    console.log(response);
                    if (response.data.type === "error") {
                        Swal.fire({
                            title: "KO!",
                            html: `${t('Contacto no enviado')}`,
                            timer: 1500,
                            showConfirmButton: false,
                            allowOutsideClick: false,
                        });
                    } else {
                        Swal.fire({
                            title: "OK!",
                            html: `${t('Contacto enviado')}`,
                            timer: 1500,
                            showConfirmButton: false,
                            allowOutsideClick: false,
                        }).then((result) => {
                            window.location.reload();
                        });
                    }
                });
        }
    };


    return (
        <>
            {/*--===== APARTMENT AREA STARTS =======--*/}
            <div className="apartment-details-left sp6">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="apartment-author-area pdright">
                                <div className="img1">
                                    {
                                        propiedad.fotos_web && propiedad.fotos_web[0] && propiedad.fotos_web[0].nombre ?
                                            <img src={ruta + "/propiedades/" + propiedad.id + "/fotos/web/" + propiedad.fotos_web[0].nombre} />
                                            : <img src={ruta + "/assets/img/no-image.jpg"} />
                                    }
                                </div>
                                <div className="space40"></div>
                                <h2>{propiedad.nombre + ", " + propiedad.calle + " " + propiedad.ciudad}</h2>
                                <h3>{propiedad.hutb}</h3>
                                <div className="space20"></div>
                                {ReactHtmlParser(propiedad.ladesc)}
                                <div className="space40"></div>
                                <div className="row">
                                    <div className="col-lg-4 col-6">
                                        <div className="list-area">
                                            <div className="icons">
                                                <i className="fa-duotone fa-home texto-azul"></i>
                                            </div>
                                            <div className="text">
                                                <a href="#">{propiedad.metros_cuadrados} M<sup>2</sup></a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-6">
                                        <div className="list-area">
                                            <div className="icons">
                                                <i className="fa-duotone fa-bed texto-azul"></i>
                                            </div>
                                            <div className="text">
                                                <a href="#">{propiedad.num_habitaciones} {t("Habitaciones")}</a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-6">
                                        <div className="list-area">
                                            <div className="icons">
                                                <i className="fa-duotone fa-users texto-azul"></i>
                                            </div>
                                            <div className="text">
                                                <a href="#">{propiedad.capacidad_maxima} {t("Adultos")}</a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-6">
                                        <div className="list-area">
                                            <div className="icons">
                                                <i className="fa-duotone fa-bath texto-azul"></i>
                                            </div>
                                            <div className="text">
                                                <a href="#">{propiedad.num_banyos} {t("Baños")}</a>
                                            </div>
                                        </div>
                                    </div>

                                    {propiedad.num_sofas_cama > 0 ?
                                        <div className="col-lg-4 col-6">
                                            <div className="list-area">
                                                <div className="icons">
                                                    <i className="fa-duotone fa-couch texto-azul"></i>
                                                </div>
                                                <div className="text">
                                                    <a href="#">{propiedad.num_sofas_cama} {t("Sofá Cama")}</a>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                                <div className="space20"></div>
                                <h3>{t("Amenities")}</h3>
                                <div className="space20"></div>
                                <div className="row">
                                    {
                                        servicios.map((unservicio, index) => (
                                            <div className="col-lg-6 col-md-6" key={index}>
                                                <div className="list-area">
                                                    <div className="icons">
                                                        <i className="fa fa-check fa-1-5x texto-azul"></i>
                                                    </div>
                                                    <div className="text">
                                                        {
                                                            (unservicio.elnombre.length > 20) ?
                                                                <Link title={unservicio.elnombre} id={"t-" + unservicio.id}>
                                                                    {unservicio.elnombre.substring(0, 20) + "..."}
                                                                </Link>
                                                                :
                                                                <a href="#">{unservicio.elnombre}</a>
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className="space40"></div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="details-leftarea">
                                <div className="apartment-contactbox">
                                    <h3>{t("Contacta con nosotros")}</h3>
                                    <div className="space16"></div>
                                    <form action="/enviapeticioncostadaurada" onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="input-area">
                                                    <input type="text" placeholder={t("Nombre") + " *"} required="" value={nombre} onChange={(e) => setNombre(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="input-area">
                                                    <input type="number" placeholder={t("Teléfono")} value={telefono} onChange={(e) => setTelefono(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="input-area">
                                                    <input type="email" placeholder={t("Email") + " *"} required="" value={email} onChange={(e) => setEmail(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="input-area">
                                                    <input type="date" placeholder={t("Entrada")} value={diaentrada} onChange={(e) => setDiaEntrada(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="input-area">
                                                    <input type="date" placeholder={t("Salida")} value={diasalida} onChange={(e) => setDiaSalida(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="input-area">
                                                    <input type="text" placeholder={t("Población y pais")} value={poblacion} onChange={(e) => setPoblacion(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="input-area">
                                                    <input type="text" placeholder={t("Idioma")} value={idioma} onChange={(e) => setIdioma(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="input-area">
                                                    <input type="number" placeholder={t("Personas")} value={personas} onChange={(e) => setPersonas(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="input-area">
                                                    <textarea placeholder="Observaciones" value={observaciones} onChange={(e) => setObservaciones(e.target.value)}></textarea>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="input-area">
                                                    <input type="checkbox" value="acepto" name="acepto" required="" checked={consentimiento} onChange={(e) => setConsentimiento(e.target.checked)} className="checkboxpersonalizado" /> <a href={"/" + preidioma + t("terminos")}>{t("Acepto los términos y condiciones")}</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="space24"></div>
                                        <div className="col-lg-12">
                                            <div className="input-area text-end">
                                                <button type="submit" className="header-btn4">{t("Enviar")}</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <div className="space40"></div>

                                <div className="details-area">
                                    <h3>{t("Galeria")}</h3>
                                    <div className="space32"></div>
                                    <div className="details-slider owl-carousel">
                                        {
                                            propiedad.fotos_web.map((unafoto, index) => (
                                                <div className="img1" key={index}>
                                                    <img src={ruta + "/propiedades/" + propiedad.id + "/fotos/web/" + unafoto.nombre} alt="" />
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>

                                <div className="map-and-friends">
                                    <MapContainer center={[propiedad.latitud, propiedad.longitud]} zoom={18} scrollWheelZoom={false}>
                                        <TileLayer
                                            attribution='Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
                                            url="https://api.mapbox.com/styles/v1/magnoapartments/clm9hgl2p015701pjd18p6vrv/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoibWFnbm9hcGFydG1lbnRzIiwiYSI6ImNsY29uNmx4bjAwMWszdm4yYmY5aDR5YngifQ.6pEwCqEnsjUoXQQxu-Tcng"
                                        />
                                        <Marker position={[propiedad.latitud, propiedad.longitud]}></Marker>
                                    </MapContainer>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*--===== APARTMENT AREA ENDS =======--*/}
        </>
    )
}
