import React from 'react';

import "./../../../../css/web/costadaurada/bootstrap.min.css";
import "./../../../../css/web/costadaurada/aos.css";
import "./../../../../css/web/costadaurada/magnific-popup.css";
import "./../../../../css/web/costadaurada/mobile.css";
import "./../../../../css/web/costadaurada/owlcarousel.min.css";
import "./../../../../css/web/costadaurada/sidebar.css";
import "./../../../../css/web/costadaurada/slick-slider.css";
import "./../../../../css/web/costadaurada/nice-select.css";
import "./../../../../css/web/costadaurada//main.css";

import Scripts from './Compartido/Scripts.jsx';
import Preloader from './Compartido/Preloader.jsx';
import Header from './Compartido/Header.jsx';
import Footer from './Compartido/Footer.jsx';
import CabeceraInterior from './Compartido/CabeceraInterior.jsx';

import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import Junta from './Organos/Junta.jsx';

const OrganosDeGoberno = (props) => {

    const { t } = useTranslation();

    return (
        <body className="homepage3-body">
            <Helmet>
                <title>{props.meta_titulo + " - " + t("Órganos de Gobierno")}</title>
                <meta name="description" content={props.meta_descripcion} />
                <meta name="keywords" content={props.meta_keywords} />

                <meta property="og:title" content={props.meta_titulo} />
                <meta property="og:description" content={props.meta_descripcion} />
                <meta property="og:image" content="imagen" />
                <meta property="og:type" content={"website"} />

                <meta name="twitter:card" content="sumary_large_image" />
                <meta name="twitter:site" content="@aaturistics" />
                <meta name="twitter:creator" content="@aaturistics" />
                <meta name="twitter:title" content={props.meta_titulo} />
                <meta name="twitter:description" content={props.meta_descripcion} />
                <meta name="twitter:image" content="imagen" />
                <meta name="twitter:url" content={props.websgrupo.url_fiscal} />
            </Helmet>
            <Scripts />
            <Preloader />
            <Header
                config={props.websgrupo}
                ruta={props.ruta}
                lang={props.lang}
                destinos={props.destinos}
                experiencias={props.experiencias}
                idiomas={props.idiomas}
            />
            <CabeceraInterior
                titulo={t("Órganos de Gobierno")}
                ruta={props.ruta}
                lang={props.lang}
            />
            <Junta
                personas={props.personas}
                ruta={props.ruta}
            />
            <Footer
                config={props.websgrupo}
                ruta={props.ruta}
                lang={props.lang}
            />
        </body>
    );
}

export default OrganosDeGoberno;
