import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function CabeceraInterior({ titulo, lang, ruta, titulo2 = '' }) {

    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    const [num, setNum] = useState(1);

    function randomNumberInRange(min, max) {
        // 👇️ Get the number between min (inclusive) and max (inclusive)
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    useEffect(() => {
        const interval = setInterval(() => {
            // 👇️ Generate a random number between 1 and 11
            setNum(randomNumberInRange(1, 11));
        }, 5000); // 👈️ runs every 5 second

        return () => {
            clearInterval(interval);
        };
    }, []);


    return (
        <>
            {/*--===== HERO AREA STARTS =======--*/}
            <div className="inner-main-hero-area">
                <div className="img1">
                    <img src={"/images/web/costadaurada/banners/CAP_PETITA_" + num + ".jpg"} alt="" />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner-heading header-heading">
                                <h2>{titulo}</h2>
                                <div className="space24"></div>
                                <p><a href={"/" + preidioma}>{t("Inicio")}<i className="fa-solid fa-angle-right"></i></a>{titulo2 ? titulo2 : titulo}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            {/*--===== HERO AREA ENDS =======--*/}
        </>
    );
}
