import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function Header({ config, meta_titulo, meta_descripcion, meta_keywords, ruta, lang, destinos }) {

    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    useEffect(() => {
        document.title = meta_titulo
        let titleMeta = document.querySelector("meta[name='title']")
        titleMeta.setAttribute("content", meta_titulo)

        let descMeta = document.querySelector("meta[name='description']")
        descMeta.setAttribute("content", meta_descripcion)

        let keyMeta = document.querySelector("meta[name='keywords']")
        keyMeta.setAttribute("content", meta_keywords)

        if (config.id === 1) {
            let favicon = ruta + "/web/favicon/favicon.ico"
            let fav = document.querySelector("link[rel='icon']")
            fav.setAttribute("href", favicon)
        }
        else {
            let favicon = ruta + "/web/favicon/" + config.id + "/favicon.ico"
            let fav = document.querySelector("link[rel='icon']")
            fav.setAttribute("href", favicon)
        }

        if (config.css_personalizado) {
            const linkElement = document.createElement('link');
            linkElement.rel = 'stylesheet';
            linkElement.type = 'text/css';
            linkElement.href = config.css_personalizado;
            const headElement = document.head;
            headElement.appendChild(linkElement);
        }

    }, []);


    return (
        <>
            {/*--=====HEADER START=======--*/}
            <header>
                <div className="header-area homepage3 header header-sticky d-none d-lg-block " id="header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="header-elements">
                                    <div className="site-logo">
                                        <a href={"/" + preidioma}>
                                            {
                                                config.logo_fiscal != "" ?
                                                    <img src={ruta + "/web/logo/" + config.logo_fiscal} alt="" />
                                                    : <img src="https://www.bedloop.com/imatges/logo.webp" alt="" />
                                            }
                                        </a>
                                    </div>
                                    <div className="main-menu">
                                        <ul>
                                            <li>
                                                <a href="#">{t("Quienes somos")} <i className="fa-solid fa-angle-down"></i></a>
                                                <ul className="dropdown-padding">
                                                    <li><a href={"/" + preidioma + t("sobre-nosotros")}>{t("Quienes somos")}</a></li>
                                                    <li><a href="#">{t("Qué hacemos")}</a></li>
                                                    <li><a href={"/" + preidioma + t("los-asociados")}>{t("Asociados")}</a></li>
                                                </ul>
                                            </li>
                                            <li>
                                                <a href="#">{t("Club colaboradores")} <i className="fa-solid fa-angle-down"></i></a>
                                                <ul className="dropdown-padding">
                                                    <li><a href="#">{t("Iniciativas club AAT")}</a></li>
                                                </ul>
                                            </li>
                                            <li><a href="#">{t("Alquiler")}</a></li>
                                            <li>
                                                <a href="#">{t("Destinos")} <i className="fa-solid fa-angle-down"></i></a>
                                                <ul className="dropdown-padding">
                                                    {
                                                        destinos.map((undestino, index) => (
                                                            <li key={index}>
                                                                <a href={"/" + preidioma + t("apartamentos") + "-" + undestino.laurl}>{undestino.elnombre}</a>
                                                            </li>
                                                        ))
                                                    }
                                                    {/*<li><a href="#">{t("Costa Daurada")}</a></li>
                                                    <li><a href="#">{t("Terres de l'Ebre")}</a></li>*/}
                                                </ul>
                                            </li>
                                            <li>
                                                <a href="#">{t("Experiencias")} <i className="fa-solid fa-angle-down"></i></a>
                                                <ul className="dropdown-padding">
                                                    <li><a href="#">{t("Costa Daurada")}</a></li>
                                                    <li><a href="#">{t("Terres de l'Ebre")}</a></li>
                                                </ul>
                                            </li>
                                            <li><a href={"/" + preidioma + t("blog")}>{t("Noticias")}</a></li>
                                            <li><a href={"/" + preidioma + t("contacto")}>{t("Contacto")}</a></li>
                                            <li><a href="#">{t("Asóciate")}</a></li>
                                            <li><a href="#">{t("Bolsa de Trabajo")}</a></li>
                                        </ul>
                                    </div>
                                    <div className="btn-area-author">
                                        <ul>
                                            <li><a href={config.url_facebook}><i className="fa-brands fa-facebook-f"></i></a></li>
                                            <li>
                                                <a href={config.url_twitter}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="twitterlogo">{/*--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--*/}<path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" /></svg>
                                                </a>
                                            </li>
                                            <li><a href={config.url_linkedin}><i className="fa-brands fa-linkedin-in"></i></a></li>
                                            <li><a href={config.url_pinterest} className="m-0"><i className="fa-brands fa-youtube"></i></a></li>
                                        </ul>
                                    </div>
                                    <div className="body-overlay"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            {/*--=====HEADER END =======--*/}

            {/*--===== MOBILE HEADER STARTS =======--*/}
            <div className="mobile-header mobile-haeder3 d-block d-lg-none">
                <div className="container-fluid">
                    <div className="col-12">
                        <div className="mobile-header-elements">
                            <div className="mobile-logo">
                                <a href={"/" + preidioma}>
                                    {
                                        config.logo_fiscal != "" ?
                                            <img src={ruta + "/web/logo/" + config.logo_fiscal} alt="" />
                                            : <img src="https://www.bedloop.com/imatges/logo.webp" alt="" />
                                    }
                                </a>
                            </div>
                            <div className="mobile-nav-icon dots-menu">
                                <i className="fa-solid fa-bars"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mobile-sidebar mobile-sidebar3">
                <div className="logosicon-area">
                    <div className="logos">
                        {
                            config.logo_fiscal != "" ?
                                <img src={ruta + "/web/logo/" + config.logo_fiscal} alt="" />
                                : <img src="https://www.bedloop.com/imatges/logo.webp" alt="" />
                        }
                    </div>
                    <div className="menu-close">
                        <i className="fa-solid fa-xmark"></i>
                    </div>
                </div>
                <div className="mobile-nav mobile-nav1">
                    <ul className="mobile-nav-list nav-list1">
                        <li>
                            <a href="#" >{t("Quienes somos")} </a>
                            <ul className="sub-menu">
                                <li><a href={"/" + preidioma + t("sobre-nosotros")}>{t("Quienes somos")}</a></li>
                                <li><a href="#">{t("Qué hacemos")}</a></li>
                                <li><a href={"/" + preidioma + t("los-asociados")}>{t("Asociados")}</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="#">{t("Club colaboradores")} <i className="fa-solid fa-angle-down"></i></a>
                            <ul className="sub-menu">
                                <li><a href="#">{t("Iniciativas club AAT")}</a></li>
                            </ul>
                        </li>
                        <li><a href="#">{t("Alquiler")}</a></li>
                        <li>
                            <a href="#">{t("Destinos")} <i className="fa-solid fa-angle-down"></i></a>
                            <ul className="sub-menu">
                                <li><a href="#">{t("Costa Daurada")}</a></li>
                                <li><a href="#">{t("Terres de l'Ebre")}</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="#">{t("Experiencias")} <i className="fa-solid fa-angle-down"></i></a>
                            <ul className="sub-menu">
                                <li><a href="#">{t("Costa Daurada")}</a></li>
                                <li><a href="#">{t("Terres de l'Ebre")}</a></li>
                            </ul>
                        </li>
                        <li><a href={"/" + preidioma + t("blog")}>{t("Noticias")}</a></li>
                        <li><a href={"/" + preidioma + t("contacto")}>{t("Contacto")}</a></li>
                        <li><a href="#">{t("Asóciate")}</a></li>
                        <li><a href="#">{t("Bolsa de Trabajo")}</a></li>
                    </ul>

                    <div className="allmobilesection">
                        <a href="contact.html" className="header-btn3">{t("Empecemos")} <span><i className="fa-solid fa-arrow-right"></i></span></a>
                        <div className="single-footer">
                            <h3>{t("Datos de contacto")}</h3>
                            <div className="footer1-contact-info">
                                <div className="contact-info-single">
                                    <div className="contact-info-icon">
                                        <i className="fa-solid fa-phone-volume"></i>
                                    </div>
                                    <div className="contact-info-text">
                                        <a href={"tel:" + config.telefono_fiscal}>{config.telefono_fiscal}</a>
                                    </div>
                                </div>

                                <div className="contact-info-single">
                                    <div className="contact-info-icon">
                                        <i className="fa-solid fa-envelope"></i>
                                    </div>
                                    <div className="contact-info-text">
                                        <a href={"mailto:" + config.correo_fiscal}>{config.correo_fiscal}</a>
                                    </div>
                                </div>

                                <div className="single-footer">
                                    <h3>{t("Direccion")}</h3>
                                    <div className="contact-info-single">
                                        <div className="contact-info-icon">
                                            <i className="fa-solid fa-location-dot"></i>
                                        </div>
                                        <div className="contact-info-text">
                                            <a href="mailto:at@tinet.cat" >{config.direccion_fiscal}<br /> {config.codifo_postal_fiscal + " " + config.poblacion_fiscal} Tarragona</a>
                                        </div>
                                    </div>
                                </div>

                                <div className="single-footer">
                                    <h3>Social Links</h3>
                                    <div className="social-links-mobile-menu">
                                        <ul>
                                            <li><a href="#"><i className="fa-brands fa-facebook-f"></i></a></li>
                                            <li><a href="#"><i className="fa-brands fa-instagram"></i></a></li>
                                            <li><a href="#"><i className="fa-brands fa-linkedin-in"></i></a></li>
                                            <li><a href="#"><i className="fa-brands fa-youtube"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*--===== MOBILE HEADER STARTS =======--*/}
        </>
    )
}
