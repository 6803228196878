import { useTranslation } from "react-i18next";

export default function AreaContacto({ config, lang }) {
    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    return (
        <>
            {/*--===== CONTACT AREA STARTS =======--*/}
            <div className="contact1-section-area sp5" >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="heading2">
                                <h5 data-aos="fade-left" data-aos-duration="800" style={{ lineHeight: "30px" }}>{t("Club de Colaboradores")}</h5>
                                <div className="space20"></div>
                                <h2 className="text-anime-style-3">{t("Convenios de colaboración con empresas externas")}</h2>
                                <div className="space8"></div>
                                <p data-aos="fade-left" data-aos-duration="1000">{t("La idea del Club de Colaboradores nace con el objetivo de aportar valores a nuestros asociados, ofreciendo una negociación de ventajas preferenciales, privilegios y mejoras en servicios y productos que pueden ser de utilidad.")}</p>
                                <div className="space8"></div>
                                <p data-aos="fade-left" data-aos-duration="1200">{t("Este es un servicio que está en continua ampliación")}</p>
                                <div className="btn-area1" data-aos="fade-left" data-aos-duration="1200">
                                    <a href={"/" + preidioma + t("contacto")} className="header-btn2">{t("Infórmese")}</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1"></div>
                        <div className="col-lg-4">
                            <div className="contact-img1" data-aos="flip-right" data-aos-duration="1000">
                                <img src="/images/web/costadaurada/home/colaboradores.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*--===== CONTACT AREA ENDS =======--*/}
        </>
    )
}
