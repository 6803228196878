import { React, useState } from "react";
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';

export default function Content({ lang, newsletters, contadores }) {

    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    const [show, setShow] = useState('none');
    const handleShow = () => {
        if (show == 'none') setShow('flex');
        else setShow('none');
    };

    return (
        <div className="mt-2 apartment-inner2-section-area sp5 bg2">
            <div className="container">
                <div className="sections_group">
                    <div className="entry-content">
                        <div className="section section-filters">
                            <div className="clearfix section_wrapper">
                                <div id="Filters" className="column one">
                                    <ul className="mb-2 filters_buttons" style={{ display: "flex" }}>
                                        <li className="mt-3 label separacionfiltro">
                                            {t("Filtros de búsqueda")}
                                        </li>
                                        <li className="anyos separacionfiltro curpointer">
                                            <a className="open header-btn2" onClick={handleShow}><i className="fa-solid fa-page"></i>{t("Años")}<i className="fa-solid fa-caret-down"></i></a>
                                        </li>
                                        <li className="reset separacionfiltro curpointer">
                                            <a className="close header-btn2" data-rel="*" href={"/" + preidioma + t("newsletters") + "/" + t("total")}><i className="fa-solid fa-xmark"></i> {t("Ver todos")}</a>
                                        </li>
                                    </ul>
                                    <div className="filters_wrapper" style={{ display: show }}>
                                        <ul className="anyos" style={{ display: show, flexWrap: "wrap" }}>
                                            <li className="mt-3 reset-inner anyosfiltro curpointer">
                                                <a className="header-btn2" data-rel="*" href={"/" + preidioma + t("newsletters") + "/" + t("total")}>{t("Ver todos")}</a>
                                            </li>
                                            {
                                                contadores.map((uncontador, index) => (
                                                    <li key={index} className="mt-3 anyosfiltro curpointer">
                                                        <a className="header-btn2" data-rel={"." + uncontador.anyo} href={"/" + preidioma + t("newsletters") + "/anyo/" + uncontador.anyo}>{uncontador.anyo}({uncontador.total})</a>
                                                    </li>
                                                ))
                                            }
                                            <li className="mt-3 close anyosfiltro curpointer">
                                                <a onClick={handleShow} className="curpointer header-btn2"><i className="fa-solid fa-xmark"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="section" style={{ paddingTop: "60px" }}>
                            <div className="clearfix section_wrapper">

                                {/* **** NEWSLETTERS ***** */}
                                <div className="sections_group normas">
                                    <div className="section">
                                        <div className="clearfix section_wrapper">

                                            <div className="clearfix mt-20 column_attr align_center">
                                                <h6 className="landing4-heading grey" style={{ paddingBottom: "3px", borderBottom: "1px solid  #00b7d9", borderColor: "#00b7d9" }}>{t("NEWSLETTERS")}</h6>
                                                <hr className="no_line" style={{ margin: "0 auto 15px auto" }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="entry_content">
                                        <div className="section">
                                            <div className="clearfix section_wrapper">
                                                <div className="row">
                                                    {
                                                        newsletters.map((unnewsletter, index) => (
                                                            <div className="mt-4 col-lg-4 minh-200" style={{ borderBottom: "1px solid  #00b7d9" }} key={index}>
                                                                <h3 className="fs-18 lh-25">
                                                                    <img src="/images/web/costadaurada/corporation2-relations-pic2.png" width="30" height="36" className="imgnewsletters" style={{ marginRight: "10px" }} />
                                                                    {unnewsletter.nombre}
                                                                </h3>
                                                                <div className="space8"></div>
                                                                <h6 style={{ fontWeight: "400" }}> {new Date(unnewsletter.created_at).toLocaleDateString()}</h6>
                                                                <div className="space8"></div>
                                                                <div style={{ width: "100%", paddingBottom: "3px", marginBottom: "20px", lineHeight: "21px" }} className="fs-13">
                                                                    {ReactHtmlParser(unnewsletter.respuesta)}
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* ****FIN NEWSLETTERS ***** */}

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );

}
