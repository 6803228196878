import ReactHtmlParser from 'react-html-parser';

export default function TextosCookies({ cookies, ruta }) {
    return (
        <>
            {/*--===== COOKIES TEXT AREA STARTS =======--*/}
            <div className="blog-details-leftarea sp6">
                <div className="container">
                    <div className="row">
                        <div className="m-auto col-lg-7">
                            <div className="blog-author-area">
                                <div className="img1">
                                    {
                                        cookies.imagen_cabecera ?
                                            <img src={ruta + "/" + cookies.imagen_cabecera} alt="" />
                                            :
                                            <img src="/images/web/bedloop-nofoto.png" alt="" />
                                    }
                                </div>
                                <div className="space40"></div>
                                {ReactHtmlParser(cookies.ladesc)}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/*--===== COOKIES TEXT AREA ENDS =======--*/}
        </>
    )
}
