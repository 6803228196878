import { useTranslation } from "react-i18next";
import ReactHtmlParser from 'react-html-parser';

export default function TextoExperiencia({ experiencia, ruta }) {
    const { t } = useTranslation();

    return (
        <>
            {/*--===== BLOG AREA STARTS =======--*/}
            <div className="blog-details-leftarea sp6">
                <div className="container">
                    <div className="row">
                        <div className="m-auto col-lg-3">
                            <h5 data-aos="fade-left" data-aos-duration="800" className="aos-init aos-animate tituloredondeado">{experiencia.elnombre}</h5>
                        </div>
                    </div>
                    <div className="space40"></div>
                    <div className="row">
                        <div className="m-auto col-lg-7">
                            <div className="blog-author-area">
                                <div className="space40"></div>
                                {ReactHtmlParser(experiencia.eltexto)}
                                <div className="space16"></div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/*--===== BLOG AREA ENDS =======--*/}
        </>
    )
}
