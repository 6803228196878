import { useTranslation } from "react-i18next";

export default function TextoSobreNosotros({ sobrenosotros, personas, ruta }) {

    const { t } = useTranslation();

    return (
        <>
            {/*--===== ABOUT AREA STARTS =======--*/}
            <div className="about1-section-area sp1 bg1">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5">
                            <div className="about-header heading3">
                                <h5 data-aos="fade-left" data-aos-duration="800">{t("Quienes somos")}</h5>
                                <div className="space20"></div>
                                <h2 className="text-anime-style-3">{t("Quienes somos")}</h2>
                                <div className="space16"></div>
                                <p data-aos="fade-left" data-aos-duration="900">Nuestra Asociación fue fundada el 19 de julio de 1977. En la actualidad representamos a más de 80 empresas y profesionales del sector de apartamentos, que ofrecen su oferta legalizada de apartamentos turísticos, en todo el territorio, un global de más de 5.000 alojamientos y 24.734 plazas, distribuidos a lo largo de toda la Costa Daurada y las Terres de l’Ebre, donde recibimos y acomodamos a millones de visitantes.</p>
                                <div className="space16"></div>
                                <p data-aos="fade-left" data-aos-duration="1100">Dedicated apartment specialists, we are committed to providing personalized service, guiding you through an every step of your apartment search with expertise.</p>
                                <div className="space32"></div>
                            </div>
                        </div>
                        <div className="col-lg-1"></div>
                        <div className="col-lg-6">
                            <div className="space60 d-lg-none d-block"></div>
                            <div className="about-images">
                                <div className="img1 reveal image-anime">
                                    <img src="assets/img/all-images/about/about-img1.png" alt="" />
                                </div>
                                <div className="img2 reveal image-anime">
                                    <img src="assets/img/all-images/about/about-img2.png" alt="" />
                                </div>
                                <div className="elements">
                                    <img src="assets/img/elements/elements12.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about1-section-area sp1 bg1">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="space60 d-lg-none d-block"></div>
                            <div className="about-images">
                                <div className="img1 reveal image-anime">
                                    <img src="assets/img/all-images/about/about-img1.png" alt="" />
                                </div>
                                <div className="img2 reveal image-anime">
                                    <img src="assets/img/all-images/about/about-img2.png" alt="" />
                                </div>
                                <div className="elements">
                                    <img src="assets/img/elements/elements12.png" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1"></div>
                        <div className="col-lg-5">
                            <div className="about-header heading3">
                                <h5 data-aos="fade-left" data-aos-duration="800">{t("Quienes somos")}</h5>
                                <div className="space20"></div>
                                <h2 className="text-anime-style-3">{t("Nuestros servicios")}</h2>
                                <div className="space16"></div>
                                <ul data-aos="fade-left" data-aos-duration="900">
                                    <li>Asesoramiento Jurídico, Fiscal, Laboral.</li>
                                    <li>Atención personalizada.</li>
                                    <li>Intercambio de opiniones, información y experiencias.</li>
                                    <li>Tramitación de documentos.</li>
                                    <li>Tramitación de demandas de alquiler.</li>
                                    <li>Envío de circulares informativas diarias y comunicados informativos sobre temas puntuales y diversos que pueden afectar a nuestros asociados y a nuestro sector.</li>
                                    <li>Formación para los asociados.</li>
                                    <li>Promoción de los asociados: Catálogo y Web AT.</li>
                                    <li>Convenios con empresas externas.</li>
                                    <li>Estadísticas de ocupación.</li>
                                    <li>Colaboración con otras entidades.</li>
                                </ul>
                                <div className="space32"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about1-section-area sp1 bg1">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5">
                            <div className="about-header heading3">
                                <h5 data-aos="fade-left" data-aos-duration="800">{t("Quienes somos")}</h5>
                                <div className="space20"></div>
                                <h2 className="text-anime-style-3">{t("Instituciones vinculadas al turismo")}</h2>
                                <div className="space16"></div>
                                <ul data-aos="fade-left" data-aos-duration="900">
                                    <li>Agencia Catalana de Turismo / Dirección General de Turismo (Generalitat de Catalunya)</li>
                                    <li>Patronato de Turismo de la Diputación de Tarragona en Costa Daurada y Terres de l'Ebre / Patronatos Municipales de Turismo</li>
                                    <li>Federación Catalana de Apartamentos Turísticos (FEDERATUR)</li>
                                    <li>Federación Empresarial de Hostelería y Turismo de la Província de Tarragona (FEHT)</li>
                                    <li>Parque Científico y Tecnológico del Turismo y Ocio de Cataluña (EURECAT)</li>
                                    <li>Fundación de Estudios Turísticos – Observatorio Costa Daurada y Terres de l'Ebre</li>
                                </ul>
                                <div className="space32"></div>
                            </div>
                        </div>
                        <div className="col-lg-1"></div>
                        <div className="col-lg-6">
                            <div className="space60 d-lg-none d-block"></div>
                            <div className="about-images">
                                <div className="img1 reveal image-anime">
                                    <img src="assets/img/all-images/about/about-img1.png" alt="" />
                                </div>
                                <div className="img2 reveal image-anime">
                                    <img src="assets/img/all-images/about/about-img2.png" alt="" />
                                </div>
                                <div className="elements">
                                    <img src="assets/img/elements/elements12.png" alt="" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            {/*--===== ABOUT AREA ENDS =======-*/}
        </>
    );
}
