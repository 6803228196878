import { useEffect } from "react";

const Scripts = () => {

    const jqueryScript = "/vendor/costadaurada/plugins/jquery-3-6-0.min.js";

    const scriptSources = [
        "/vendor/costadaurada/plugins/aos.js",
        "/vendor/costadaurada/plugins/counter.js",
        "/vendor/costadaurada/plugins/sidebar.js",
        "/vendor/costadaurada/plugins/magnific-popup.js",
        "/vendor/costadaurada/plugins/mobilemenu.js",
        "/vendor/costadaurada/plugins/owlcarousel.min.js",
        "/vendor/costadaurada/plugins/nice-select.js",
        "/vendor/costadaurada/plugins/waypoints.js",
        "/vendor/costadaurada/plugins/slick-slider.js",
        "/vendor/costadaurada/plugins/circle-progress.js",
        "/vendor/costadaurada/plugins/gsap.min.js",
        "/vendor/costadaurada/plugins/ScrollTrigger.min.js",
        "/vendor/costadaurada/plugins/Splitetext.js",
        "/vendor/costadaurada/main.js"
    ];

    useEffect(() => {
        const jqueryScriptElement = document.createElement("script");
        jqueryScriptElement.src = jqueryScript;
        document.body.appendChild(jqueryScriptElement);

        jqueryScriptElement.onload = () => {
            const scripts = scriptSources.map((src) => {
                const script = document.createElement("script");
                script.src = src;
                document.body.appendChild(script);
                return script;
            });

            return () => {
                scripts.forEach((script) => document.body.removeChild(script));
            };
        }
    }, []);

    return null;
};

export default Scripts;
