import { useTranslation } from "react-i18next";
import ReactHtmlParser from 'react-html-parser';

export default function ListaColaboradores({ lang, colaboradores, ruta }) {
    const { t } = useTranslation();

    return (
        <>
            <div className="mt-2 apartment-inner2-section-area sp4 bg2">
                <div className="container">
                    <div className="row" style={{ textAlign: "center" }}>
                        <div className="m-auto col-lg-4">
                            <h5 data-aos="fade-left" data-aos-duration="800" className="aos-init aos-animate tituloredondeado">{t("Club Colaborador")}</h5>
                        </div>
                    </div>
                    <div className="space40"></div>
                    <div className="row">
                        {
                            colaboradores.map((uncolaborador, index) => (
                                <div className="col-lg-4 col-md-6" data-aos="zoom-in-up" data-aos-duration={800 + (index * 100)} key={index}>
                                    <div className="apartment-boxarea">
                                        <div className="img1">
                                            {uncolaborador.imagen ?
                                                <img src={ruta + "/ventajas/" + uncolaborador.id + "/" + uncolaborador.imagen} alt="" />
                                                :
                                                ""
                                            }
                                        </div>
                                        <div className="content-area">
                                            <a href="#">{uncolaborador.elnombre}</a>
                                            <div className="space16"></div>
                                            {ReactHtmlParser(uncolaborador.eltexto)}
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </>
    );

}
