import { useEffect } from "react";

const Scripts = () => {

    const jqueryScript = "/vendor/costadaurada/plugins/jquery-3-6-0.min.js";

    const scriptSources = [
        "/vendor/costadaurada/plugins/bootstrap.min.js",
        "/vendor/costadaurada/plugins/gsap.min.js",
        "/vendor/costadaurada/plugins/aos.js",
        "/vendor/costadaurada/plugins/counter.js",
        "/vendor/costadaurada/plugins/sidebar.js",
        "/vendor/costadaurada/plugins/magnific-popup.js",
        "/vendor/costadaurada/plugins/mobilemenu.js",
        "/vendor/costadaurada/plugins/owlcarousel.min.js",
        "/vendor/costadaurada/plugins/nice-select.js",
        "/vendor/costadaurada/plugins/waypoints.js",
        "/vendor/costadaurada/plugins/slick-slider.js",
        "/vendor/costadaurada/plugins/circle-progress.js",
        "/vendor/costadaurada/plugins/ScrollTrigger.min.js",
        "/vendor/costadaurada/plugins/Splitetext.js",
        "/vendor/costadaurada/main.js"
    ];

    const loadScript = (src) => {
        return new Promise((resolve, reject) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => resolve();
            script.onerror = () => reject(new Error(`Error loading script: ${src}`));
            document.body.appendChild(script);
        });
    };

    useEffect(() => {

        const loadScripts = async () => {
            try {
                await loadScript(jqueryScript);
                for (const src of scriptSources) {
                    await loadScript(src);
                }
            } catch (error) {
                console.error(error);
            }
        };

        loadScripts();

        // Cleanup function to remove scripts
        return () => {
            const allScripts = [jqueryScript, ...scriptSources];
            allScripts.forEach((src) => {
                const script = document.querySelector(`script[src="${src}"]`);
                if (script) {
                    document.body.removeChild(script);
                }
            });
        };
    }, []);

    return null;
};

export default Scripts;
