import { useTranslation } from "react-i18next";
import ReactHtmlParser from 'react-html-parser';

export default function TextoDestino({ destino, ruta }) {
    const { t } = useTranslation();

    return (
        <>
            {/*--===== BLOG AREA STARTS =======--*/}
            <div className="blog-details-leftarea sp6">
                <div className="container">
                    <div className="row">
                        <div className="m-auto col-lg-7">
                            <div className="blog-author-area">
                                <div className="img1">
                                    {destino.imagenPrincipal ?
                                        <img src={ruta + "/destinos/" + destino.id + "/" + destino.imagenPrincipal.imagen} alt="" />
                                        :
                                        <img src="/images/web/bedloop-nofoto.png" alt="" />
                                    }
                                </div>
                                <div className="space40"></div>
                                {ReactHtmlParser(destino.eltexto)}
                                <div className="space16"></div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/*--===== BLOG AREA ENDS =======--*/}
        </>
    )
}
