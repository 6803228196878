import { React, useState } from "react";
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import ReactHtmlParser from 'react-html-parser';

export default function Content({ lang, apartur, contapartur, revforce, contrevforce, ajuntament, contajuntament }) {

    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    const [showtitulo, setShowTitulo] = useState('block');

    const [showapartur, setShowApartur] = useState('none');
    const handleShowApartur = () => {
        if (showapartur == "none") {
            setShowApartur("block");
            setShowTitulo("none");
            setShowRevForce("none");
            setShowAjuntament("none");
        }
        else setShowApartur("none");
    }


    const [showrevforce, setShowRevForce] = useState('none');
    const handleShowRevForce = () => {
        if (showrevforce == "none") {
            setShowRevForce("block");
            setShowTitulo("none");
            setShowApartur("none")
            setShowAjuntament("none");
        }
        else setShowRevForce("none");
    }

    const [showajuntament, setShowAjuntament] = useState('none');
    const handleShowAjuntament = () => {
        if (showajuntament == "none") {
            setShowAjuntament("block");
            setShowTitulo("none");
            setShowApartur("none");
            setShowRevForce("none");
        }
        else setShowAjuntament("none");
    }

    const [show, setShow] = useState('none');
    const handleShow = () => {
        if (show == 'none') setShow('flex');
        else setShow('none');
    };

    const handleSearch = (tipo, anyo) => {

        let token = document.getElementById('meta_token').getAttribute('content');

        let url = "";

        switch (tipo) {
            case "apartur": if (!anyo) {
                url = 'estapartur/total';
            }
            else {
                url = 'estapartur/anyo/' + anyo;
            }
                break;
            case "revforce": if (!anyo) {
                url = 'estrevforce/total';
            }
            else {
                url = 'estrevforce/anyo/' + anyo;
            }
                break;
            case "ajuntament": if (!anyo) {
                url = 'estajuntament/total';
            }
            else {
                url = 'estajuntament/anyo/' + anyo;
            }
                break;
        }

        const result = axios.get(url, {
        }, {
            headers: {
                'content-type': 'text/json',
                'X-CSRF-TOKEN': token
            }
        })
            .then((response) => {
                let respuesta = "";
                let estadisticas = response.data.estadisticas;

                if (estadisticas.length > 0) {

                    for (var i = 0; i < estadisticas.length; i++) {
                        var undocument = estadisticas[i];

                        respuesta += "<div class='col-lg-4 minh-200 mt-4' style='border-bottom: 1px solid  #00b7d9'>";
                        respuesta += "    <h3 class='fs-18 lh-25'>";
                        respuesta += "        <img src='/images/web/costadaurada/corporation2-relations-pic2.png' width='30' height='36' class='imgestadisticas' style='margin-right:10px;'/>";
                        respuesta += undocument.nombre;
                        respuesta += "    </h3>";
                        respuesta += "<h6 style='font-weight: 400;'>";
                        respuesta += new Date(undocument.created_at).toLocaleDateString();
                        respuesta += "    </h6>";
                        respuesta += "    <div style='width:100%; padding-bottom:3px; margin-bottom: 20px; line-height: 21px' class='fs-13'>";
                        respuesta += undocument.respuesta;
                        respuesta += "    </div>";
                        respuesta += "</div>";
                    }
                }

                switch (tipo) {
                    case 'apartur': document.querySelector(".eapartur").innerHTML = respuesta; break;
                    case 'revforce': document.querySelector(".erevforce").innerHTML = respuesta; break;
                    case 'ajuntament': document.querySelector(".eajuntament").innerHTML = respuesta; break;
                }
            })
    }

    return (
        <>
            {/*--===== OTHERS AREA STARTS =======--*/}
            <div className="others-author-area">
                <div className="container">
                    <div className="row">
                        <div className="m-auto col-lg-10">
                            <div className="auhtor-tabs-area">
                                <div className="boxes">
                                    <div className="icons" style={{ background: "#00b7d9" }}>
                                        <i className="fa-solid fa-newspaper"></i>
                                    </div>
                                    <div className="content">
                                        <a onClick={handleShowApartur} className="curpointer">{t("ATT COSTA DAURADA")}</a>
                                    </div>
                                </div>

                                <div className="boxes">
                                    <div className="icons" style={{ background: "#adcd04" }}>
                                        <i className="fa-solid fa-map"></i>
                                    </div>
                                    <div className="content">
                                        <a onClick={handleShowRevForce} className="curpointer">{t("RES")}</a>
                                    </div>
                                </div>

                                <div className="boxes">
                                    <div className="icons" style={{ background: "#fa3731" }}>
                                        <i className="fa-solid fa-ticket"></i>
                                    </div>
                                    <div className="content">
                                        <a onClick={handleShowAjuntament} className="white curpointer">{t("AYUNTAMIENTOS")}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*--===== OTHERS AREA ENDS =======--*/}

            <div className="mt-2 apartment-inner2-section-area sp5 bg2">
                <div className="container">
                    <div className="row" style={{ display: showtitulo }}>
                        <div className="m-auto col-lg-7" style={{ textAlign: "center" }}>
                            <h5 data-aos="fade-left" data-aos-duration="800" className="aos-init aos-animate tituloredondeado">{t("Seleccione la sección a visualizar")}</h5>
                        </div>
                    </div>
                    <div className="space40"></div>

                    {/* **** ATT COSTA DAURADA ***** */}
                    <div className="sections_group normas" style={{ display: showapartur }}>
                        <div className="section section-filters">
                            <div className="clearfix section_wrapper">
                                <div id="Filters" className="column one">
                                    <ul className="mb-2 filters_buttons" style={{ display: "flex" }}>
                                        <li className="mt-3 label separacionfiltro">
                                            {t("Filtros de búsqueda")}
                                        </li>
                                        <li className="anyos separacionfiltro curpointer">
                                            <a className="open header-btn2" onClick={handleShow}>{t("Años")}<i className="fa-solid fa-caret-down"></i></a>
                                        </li>
                                        <li className="reset separacionfiltro curpointer">
                                            <a className="close header-btn2" data-rel="*" onClick={() => { handleSearch("apartur", "") }}><i className="fa-solid fa-xmark"></i> {t("Ver todos")}</a>
                                        </li>
                                    </ul>
                                    <div className="filters_wrapper" style={{ display: show }}>
                                        <ul className="anyos" style={{ display: show, flexWrap: "wrap" }}>
                                            <li className="mt-3 reset-inner anyosfiltro curpointer">
                                                <a data-rel="*" className="header-btn2" onClick={() => { handleSearch("apartur", "") }}>{t("Ver todos")}</a>
                                            </li>
                                            {
                                                contapartur.map((uncontador, index) => (
                                                    <li key={index} className="mt-3 anyosfiltro curpointer">
                                                        <a data-rel={"." + uncontador.anyo} onClick={() => { handleSearch("apartur", uncontador.anyo) }} className="header-btn2">{uncontador.anyo}({uncontador.total})</a>
                                                    </li>
                                                ))
                                            }
                                            <li className="mt-3 close anyosfiltro curpointer">
                                                <a onClick={handleShow} className="curpointer header-btn2"><i className="fa-solid fa-xmark"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="section" style={{ paddingTop: "60px" }}>
                            <div className="clearfix section_wrapper">

                                <div className="clearfix mt-20 column_attr align_center">
                                    <h6 className="landing4-heading grey" style={{ paddingBottom: "3px", borderBottom: "1px solid  #00b7d9", borderColor: "#00b7d9" }}>{t("ATT COSTA DAURADA")}</h6>
                                    <hr className="no_line" style={{ margin: "0 auto 15px auto" }} />
                                </div>
                            </div>
                        </div>

                        <div className="entry_content">
                            <div className="section">
                                <div className="clearfix section_wrapper">
                                    <div className="row eapartur">
                                        {
                                            apartur.map((unaestadistica, index) => (
                                                <div className="mt-4 col-lg-4 minh-200" style={{ borderBottom: "1px solid  #00b7d9" }} key={index}>
                                                    <h3 className="fs-18 lh-25">
                                                        <img src="/images/web/costadaurada/corporation2-relations-pic2.png" width="30" height="36" className="imgestadisticas" style={{ marginRight: "10px" }} />
                                                        {unaestadistica.nombre}
                                                    </h3>
                                                    <div className="space8"></div>
                                                    <h6 style={{ fontWeight: "400" }}>{new Date(unaestadistica.created_at).toLocaleDateString()}</h6>
                                                    <div className="space8"></div>
                                                    <div style={{ width: "100%", paddingBottom: "3px", marginBottom: "20px", lineHeight: "21px" }} className="fs-13">
                                                        {ReactHtmlParser(unaestadistica.respuesta)}
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* ****FIN APARTUR ***** */}

                    {/* **** RES ***** */}
                    <div className="sections_group normas" style={{ display: showrevforce }}>

                        <div className="section section-filters">
                            <div className="clearfix section_wrapper">
                                <div id="Filters" className="column one">
                                    <ul className="mb-2 filters_buttons" style={{ display: "flex" }}>
                                        <li className="mt-3 label separacionfiltro">
                                            {t("Filtros de búsqueda")}
                                        </li>
                                        <li className="anyos separacionfiltro curpointer">
                                            <a className="open header-btn2" onClick={handleShow}>{t("Años")}<i className="fa-solid fa-caret-down"></i></a>
                                        </li>
                                        <li className="reset separacionfiltro curpointer">
                                            <a className="close header-btn2" data-rel="*" onClick={() => { handleSearch("revforce", "") }}><i className="fa-solid fa-xmark"></i> {t("Ver todos")}</a>
                                        </li>
                                    </ul>
                                    <div className="filters_wrapper" style={{ display: show }}>
                                        <ul className="anyos" style={{ display: show, flexWrap: "wrap" }}>
                                            <li className="mt-3 reset-inner anyosfiltro curpointer">
                                                <a data-rel="*" className="header-btn2" onClick={() => { handleSearch("revforce", "") }}>{t("Ver todos")}</a>
                                            </li>
                                            {
                                                contrevforce.map((uncontador, index) => (
                                                    <li key={index} className="mt-3 anyosfiltro curpointer">
                                                        <a data-rel={"." + uncontador.anyo} onClick={() => { handleSearch("revforce", uncontador.anyo) }} className="header-btn2">{uncontador.anyo}({uncontador.total})</a>
                                                    </li>
                                                ))
                                            }
                                            <li className="mt-3 close anyosfiltro curpointer">
                                                <a onClick={handleShow} className="curpointer header-btn2"><i className="fa-solid fa-xmark"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="section" style={{ paddingTop: "60px" }}>
                            <div className="clearfix section_wrapper">
                                <div className="clearfix mt-20 column_attr align_center">
                                    <h6 className="landing4-heading grey" style={{ paddingBottom: "3px", borderBottom: "1px solid  #00b7d9", borderColor: "#00b7d9" }}>{t("RES")}</h6>
                                    <hr className="no_line" style={{ margin: "0 auto 15px auto" }} />
                                </div>
                            </div>
                        </div>

                        <div className="entry_content">
                            <div className="section">
                                <div className="clearfix section_wrapper">
                                    <div className="row erevforce">
                                        {
                                            revforce.map((unaestadistica, index) => (
                                                <div className="mt-4 col-lg-4 minh-200" style={{ borderBottom: "1px solid  #00b7d9" }} key={index}>
                                                    <h3 className="fs-18 lh-25">
                                                        <img src="/images/web/costadaurada/corporation2-relations-pic2.png" width="30" height="36" className="imgestadisticas" style={{ marginRight: "10px" }} />
                                                        {unaestadistica.nombre}
                                                    </h3>
                                                    <div className="space8"></div>
                                                    <h6 style={{ fontWeight: "400" }}>{new Date(unaestadistica.created_at).toLocaleDateString()}</h6>
                                                    <div className="space8"></div>
                                                    <div style={{ width: "100%", paddingBottom: "3px", marginBottom: "20px", lineHeight: "21px" }} className="fs-13">
                                                        {ReactHtmlParser(unaestadistica.respuesta)}
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* **** FIN REVFORCE ***** */}

                    {/* **** AJUNTAMENTS ***** */}
                    <div className="sections_group normas" style={{ display: showajuntament }}>

                        <div className="section section-filters">
                            <div className="clearfix section_wrapper">
                                <div id="Filters" className="column one">
                                    <ul className="mb-2 filters_buttons" style={{ display: "flex" }}>
                                        <li className="mt-3 label separacionfiltro">
                                            {t("Filtros de búsqueda")}
                                        </li>
                                        <li className="anyos separacionfiltro curpointer">
                                            <a className="open header-btn2" onClick={handleShow}><i className="fa-solid fa-page"></i>{t("Años")}<i className="fa-solid fa-caret-down"></i></a>
                                        </li>
                                        <li className="reset separacionfiltro curpointer">
                                            <a className="close header-btn2" data-rel="*" onClick={() => { handleSearch("ajuntament", "") }}><i className="fa-solid fa-xmark"></i> {t("Ver todos")}</a>
                                        </li>
                                    </ul>
                                    <div className="filters_wrapper" style={{ display: show }}>
                                        <ul className="anyos" style={{ display: show, flexWrap: "wrap" }}>
                                            <li className="mt-3 reset-inner anyosfiltro curpointer">
                                                <a data-rel="*" className="header-btn2" onClick={() => { handleSearch("ajuntament", "") }}>{t("Ver todos")}</a>
                                            </li>
                                            {
                                                contajuntament.map((uncontador, index) => (
                                                    <li key={index} className="mt-3 anyosfiltro curpointer">
                                                        <a className="header-btn2" data-rel={"." + uncontador.anyo} onClick={() => { handleSearch("ajuntament", uncontador.anyo) }}>{uncontador.anyo}({uncontador.total})</a>
                                                    </li>
                                                ))
                                            }
                                            <li className="mt-3 close anyosfiltro curpointer">
                                                <a onClick={handleShow} className="curpointer header-btn2"><i className="fa-solid fa-xmark"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="section" style={{ paddingTop: "60px" }}>
                            <div className="clearfix section_wrapper">
                                <div className="clearfix mt-20 column_attr align_center">
                                    <h6 className="landing4-heading grey" style={{ paddingBottom: "3px", borderBottom: "1px solid  #00b7d9", borderColor: "#00b7d9" }}>{t("AYUNTAMIENTOS")}</h6>
                                    <hr className="no_line" style={{ margin: "0 auto 15px auto" }} />
                                </div>
                            </div>
                        </div>

                        <div className="entry_content">
                            <div className="section">
                                <div className="clearfix section_wrapper">
                                    <div className="row eajuntament">
                                        {
                                            ajuntament.map((unaestadistica, index) => (
                                                <div className="mt-4 col-lg-4 minh-200" style={{ borderBottom: "1px solid  #00b7d9" }} key={index}>
                                                    <h3 className="fs-18 lh-25">
                                                        <img src="/images/web/costadaurada/corporation2-relations-pic2.png" width="30" height="36" className="imgestadisticas" style={{ marginRight: "10px" }} />
                                                        {unaestadistica.nombre}
                                                    </h3>
                                                    <div className="space8"></div>
                                                    <h6 style={{ fontWeight: "400" }}>{new Date(unaestadistica.created_at).toLocaleDateString()}</h6>
                                                    <div className="space8"></div>
                                                    <div style={{ width: "100%", paddingBottom: "3px", marginBottom: "20px", lineHeight: "21px" }} className="fs-13">
                                                        {ReactHtmlParser(unaestadistica.respuesta)}
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* **** FIN AJUNTAMENT ***** */}
                </div>
            </div>
        </>
    );
}
